import { loginInstance, logoutInstance } from "../axiosCashier";
import { getCashierInfo } from "./objects/cashiers.service";

interface loginCashierParams {
  email: string;
  password: string;
}

interface setCashierTokenParams {
  token: string;
}

export const loginCashier = (params: loginCashierParams) => {
  const authData = { auth: { email: params.email, password: params.password } };

  return loginInstance.post("", authData).then((response: any) => {
    const token = response.data.token;
    if (token) {
      setCashierToken({ token: token });
    }

    return response.data;
  });
};

export const autoRefreshCashierToken = () => {
  return getCashierInfo();
};

export const refreshCashierToken = (response: any) => {
  const token = response.headers["x-refresh-token"];
  if (token) {
    setCashierToken({ token: token });
  } else {
    logoutCashier();
  }
};

export const logoutCashier = () => {
  logoutInstance.delete("").then((response) => {
    if (!response) return;

    localStorage.removeItem("cashier_token");
    localStorage.removeItem("drawer_balance");
    localStorage.removeItem("current_cashier");
    window.location.reload();
  });
};

const setCashierToken = (params: setCashierTokenParams) => {
  localStorage.setItem("cashier_token", params.token);
};
