import { useCurrentOrdersStore } from "../../store";
import { ICarnetOrder, ICarnetOrderStore } from "../../interfaces/storeInterfaces";

export const updateCarnetOrderEmail = (state: ICarnetOrderStore, email: string) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const carnetOrder = state.carnetOrders.find((carnetOrder: ICarnetOrder) => carnetOrder.id === currentOrdersStore.currentCarnetOrderId);

  // Add new carnetOrder if there is no current carnetOrder
  if (currentOrdersStore.currentCarnetOrderId === -1 || carnetOrder === undefined) {
    const carnetOrderId = Date.now();
    useCurrentOrdersStore.setState((state) => {
      state.setCurrentCarnetOrderId(carnetOrderId);
      return { ...state, currentCarnetOrderId: carnetOrderId };
    });
    const carnetOrder: ICarnetOrder = {
      id: carnetOrderId,
      email: email,
      phone_number: "",
      prepaid_carnets_to_reserve: []
    };
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    updatedCarnetOrders.push(carnetOrder);
    return { carnetOrders: updatedCarnetOrders };
  }

  // Update email to existing carnetOrder
  if (carnetOrder) {
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    const index = updatedCarnetOrders.findIndex((carnetOrder: ICarnetOrder) => carnetOrder.id === currentOrdersStore.currentCarnetOrderId);
    updatedCarnetOrders[index].email = email;
    return { carnetOrders: updatedCarnetOrders };
  }

  const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
  return { carnetOrders: updatedCarnetOrders };
};

export const updateCarnetOrderPhoneNumber = (state: ICarnetOrderStore, phoneNumber: string) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const carnetOrder = state.carnetOrders.find((carnetOrder: ICarnetOrder) => carnetOrder.id === currentOrdersStore.currentCarnetOrderId);

  // Add new carnetOrder if there is no current carnetOrder
  if (currentOrdersStore.currentCarnetOrderId === -1 || carnetOrder === undefined) {
    const carnetOrderId = Date.now();
    useCurrentOrdersStore.setState((state) => {
      state.setCurrentCarnetOrderId(carnetOrderId);
      return { ...state, currentCarnetOrderId: carnetOrderId };
    });
    const carnetOrder: ICarnetOrder = {
      id: carnetOrderId,
      email: "",
      phone_number: phoneNumber,
      prepaid_carnets_to_reserve: []
    };
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    updatedCarnetOrders.push(carnetOrder);
    return { carnetOrders: updatedCarnetOrders };
  }

  // Update phoneNumber to existing carnetOrder
  if (carnetOrder) {
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    const index = updatedCarnetOrders.findIndex((carnetOrder: ICarnetOrder) => carnetOrder.id === currentOrdersStore.currentCarnetOrderId);
    updatedCarnetOrders[index].phone_number = phoneNumber;
    return { carnetOrders: updatedCarnetOrders };
  }

  const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
  return { carnetOrders: updatedCarnetOrders };
};
