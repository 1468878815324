import { useTranslation } from "react-i18next";

const usePhoneNumber = (number: string | null) => {
  const { t } = useTranslation();

  if (!number) return t("base.null");

  const cleanNumber = number.replace(/[^\d+]/g, "");

  if (cleanNumber.startsWith("+48") || cleanNumber.startsWith("48")) {
    return cleanNumber.replace(/^(\+?48)(\d{3})(\d{3})(\d{3})$/, "$1 $2 $3 $4");
  } else {
    const formatted = cleanNumber.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
    return `+48 ${formatted}`;
  }
};
export default usePhoneNumber;
