import { enqueueSnackbar } from "notistack";

import { fiscalNonFiscalPrint } from "./printer.service";
import { handleFiscalNonFiscalCarnetBaseData, handleFiscalNonFiscalOrderData, handleFiscalNonFiscalTerminalPrintData } from "./printerCommons";
import { ICarnetBaseObject } from "../interfaces/carnetBaseInterfaces";
import { IOrderObject } from "../interfaces/orderInterfaces";
import { IfiscalNonFiscalPrintCustomLine, IfiscalPrinterSettings } from "../interfaces/printerInterfaces";
import { IterminalPrintData } from "../interfaces/terminalInterfaces";

const fiscalNonFiscalPrinter = (props: {
  printerSettings: IfiscalPrinterSettings;
  t: any;
  carnetBase?: ICarnetBaseObject;
  handleError?: any;
  handleResult?: any;
  handleSuccess?: any;
  merge?: boolean;
  order?: IOrderObject;
  terminalPrintData?: IterminalPrintData;
  ticketIds?: number[];
}) => {
  const { carnetBase, handleError, handleResult, handleSuccess, merge, order, printerSettings, terminalPrintData, t, ticketIds } = props;
  const { fiscalPrint, fiscalPrinterTicketPrint, paperWidth } = printerSettings;

  const handleFiscalNonFiscalPrintResult = (correct: boolean) => {
    if (correct) {
      enqueueSnackbar(t("printing.fiscal_non_fiscal_success"), { variant: "success", autoHideDuration: 2000, preventDuplicate: true });
      if (handleSuccess) handleSuccess();
    } else {
      enqueueSnackbar(t("printing.fiscal_non_fiscal_fail"), { variant: "error", autoHideDuration: 2000, preventDuplicate: true });
      if (handleError) handleError();
    }
    if (handleResult) handleResult();
  };

  let customLines: IfiscalNonFiscalPrintCustomLine[] = [];
  if (fiscalPrinterTicketPrint) {
    if (order && order.tickets) {
      const selectedTickets = ticketIds ? order.tickets.filter((ticket) => ticketIds.includes(ticket.id)) : order.tickets;
      // if ((selectedTickets.length > 1 && !merge) || selectedTickets.every((ticket) => ticket.spot_desc)) {
      if (selectedTickets.length > 1 && !merge) {
        selectedTickets.forEach((ticket, index) => {
          if (index !== 0) {
            customLines = [...customLines, { type: "empty_line" }, { type: "empty_line" }];
          }
          customLines = [...customLines, ...handleFiscalNonFiscalOrderData(order, false, paperWidth, ticket)];
        });
      } else {
        // Drukowanie grupowe
        customLines = [...handleFiscalNonFiscalOrderData(order, false, paperWidth, undefined, selectedTickets)];
      }
    } else if (carnetBase) {
      if (carnetBase.barcodes) {
        carnetBase.barcodes.forEach((barcode, index) => {
          if (index !== 0) {
            customLines = [...customLines, { type: "empty_line" }, { type: "empty_line" }];
          }
          customLines = [...customLines, ...handleFiscalNonFiscalCarnetBaseData(carnetBase, false, paperWidth, barcode)];
        });
      } else {
        customLines = [...handleFiscalNonFiscalCarnetBaseData(carnetBase, false, paperWidth)];
      }
    }
  }
  
  if (terminalPrintData) {
    customLines = [...customLines, ...handleFiscalNonFiscalTerminalPrintData(terminalPrintData, false, paperWidth)];
  }

  if (!(carnetBase || order || terminalPrintData) || !printerSettings.port.name || !fiscalPrint || customLines.length === 0) return handleFiscalNonFiscalPrintResult(false);

  fiscalNonFiscalPrint({ customLines: customLines, printerSettings: printerSettings }).then((result) => {
    handleFiscalNonFiscalPrintResult(result);
  });
};

export default fiscalNonFiscalPrinter;
