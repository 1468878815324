import { usePrintingProblems } from "./";

import { IprintingProblems } from "./usePrintingProblems";

const useOrderPrintingProblems = () => {
  const { getActiveCashierPrintingProblems, selectNextProblem, setPrintingProblemsActiveTab, saveActiveCashierPrintingProblems, setPrintingProblemsOpen } = usePrintingProblems();

  const removeOrderProblem = (orderId: number, ticketsIds: number[], setActiveId?: React.Dispatch<React.SetStateAction<number>>) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    if (setActiveId) selectNextProblem(deepCopy.skippedOrders, orderId, setActiveId);
    deepCopy.skippedOrders = deepCopy.skippedOrders.filter((id) => id !== orderId);
    deepCopy.ignoredOrders = deepCopy.ignoredOrders.filter((id) => id !== orderId);
    deepCopy.ignoredTickets = deepCopy.ignoredTickets.filter((id) => !ticketsIds.includes(id));
    selectNextTabFromOrders(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const checkIfOrderIsSkipped = (id: number) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    return deepCopy.skippedOrders.includes(id);
  };

  const countSkippedOrders = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    return deepCopy.skippedOrders.length;
  };

  const getSkippedOrders = () => {
    return getActiveCashierPrintingProblems().skippedOrders || [];
  };

  const getIgnoredTickets = () => {
    return getActiveCashierPrintingProblems().ignoredTickets || [];
  };

  const handleIgnoreOrder = (orderId: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredOrders.push(orderId);
    selectNextProblem(deepCopy.skippedOrders, orderId, setActiveId);
    deepCopy.skippedOrders = deepCopy.skippedOrders.filter((id) => id !== orderId);
    selectNextTabFromOrders(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleIgnoreOrders = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.skippedOrders.forEach((id) => {
      if (!deepCopy.ignoredOrders.includes(id)) deepCopy.ignoredOrders.push(id);
    });
    deepCopy.skippedOrders = [];
    deepCopy.ignoredTickets = [];
    selectNextTabFromOrders(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleIgnoreTicket = (ticket: number, ticketsIds: number[], orderId: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredTickets.push(ticket);
    const allTicketsAreIgnored = ticketsIds.every((id) => deepCopy.ignoredTickets.includes(id));
    if (allTicketsAreIgnored) {
      deepCopy.ignoredTickets = deepCopy.ignoredTickets.filter((id) => !ticketsIds.includes(id));
      if (!deepCopy.ignoredOrders.includes(orderId)) {
        deepCopy.ignoredOrders.push(orderId);
        selectNextProblem(deepCopy.skippedOrders, orderId, setActiveId);
        deepCopy.skippedOrders = deepCopy.skippedOrders.filter((id) => id !== orderId);
      }
    }

    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleRestoreAllTickets = (ticketsIds: number[] | undefined) => {
    if (!ticketsIds) return;
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredTickets = deepCopy.ignoredTickets.filter((id) => !ticketsIds.includes(id));
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleRestoreTicket = (ticket: number) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredTickets = deepCopy.ignoredTickets.filter((id) => id !== ticket);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const selectNextTabFromOrders = (deepCopy: IprintingProblems) => {
    const skippedOrders = deepCopy.skippedOrders;
    const skippedCarnetBases = deepCopy.skippedCarnetBases;
    const skippedCarnetOrders = deepCopy.skippedCarnetOrders;
    const skippedProductOrders = deepCopy.skippedProductOrders;

    if (skippedOrders.length !== 0) return;

    if (skippedCarnetBases.length !== 0) setPrintingProblemsActiveTab("carnets");
    else if (skippedProductOrders.length !== 0) setPrintingProblemsActiveTab("productOrders");
    else if (skippedCarnetOrders.length !== 0) setPrintingProblemsActiveTab("carnetOrders");
  };

  const handleRestoreAllOrders = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredOrders.forEach((id) => {
      if (!deepCopy.skippedOrders.includes(id)) deepCopy.skippedOrders.push(id);
    });
    deepCopy.ignoredOrders = [];
    deepCopy.ignoredTickets = [];
    saveActiveCashierPrintingProblems(deepCopy);
    setPrintingProblemsOpen(false);
  };

  return {
    checkIfOrderIsSkipped,
    removeOrderProblem,
    countSkippedOrders,
    getSkippedOrders,
    getIgnoredTickets,
    handleIgnoreOrder,
    handleIgnoreOrders,
    handleIgnoreTicket,
    handleRestoreAllTickets,
    handleRestoreTicket,
    handleRestoreAllOrders
  };
};
export default useOrderPrintingProblems;
