import { useLayoutEffect, useState } from "react";

import { useBaseStore } from "../store";

import { fetchOrder } from "../services/objects/orders.service";
import { handleFetchObjectResponse } from "../services/responseDataHandler";
import { IOrderObject } from "../interfaces/orderInterfaces";

const useOrder = (id?: number) => {
  const refresh = useBaseStore((state) => state.refresh);
  const setGlobalLoader = useBaseStore((state) => state.setGlobalLoader);
  const [order, setOrder] = useState<IOrderObject | undefined>(undefined);

  const fetchObject = () => {
    if (!id || id === -1) return;

    fetchOrder({ id: id }).then((response) => {
      handleFetchObjectResponse({ response: response, setObject: setOrder, setGlobalLoader: setGlobalLoader });
    });
  };

  useLayoutEffect(() => {
    fetchObject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refresh]);

  return { order, setOrder };
};

export default useOrder;
