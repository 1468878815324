import { IWithShadow } from "../../interfaces/sharedComponentInterfaces";

const WithShadow = (props: IWithShadow) => {
  const { children, customClasses, hoverable = true, onClick, pulse, testId, tooltipId, tooltipText } = props;

  const baseClasses = "flex bg-zinc-100 overflow-hidden";
  let pulseClasses = "";
  let shadowClasses = "shadow-lg hover:shadow transition-shadow";
  let pointerClasses = "";
  if (!hoverable) {
    shadowClasses = "shadow-lg";
  }
  if (pulse) {
    pulseClasses = "animate-pulse";
  }
  if (onClick) {
    pointerClasses = "cursor-pointer";
  }

  return (
    <section
      className={`${baseClasses} ${shadowClasses} ${pointerClasses} ${pulseClasses} ${customClasses}`}
      onClick={onClick}
      data-testid={testId}
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltipText}>
      {children}
    </section>
  );
};

export default WithShadow;
