export const electronAlert = (message: string) => {
  alert(message);
  if (window.api?.fixFocus) window.api.fixFocus();
};

export const electronConfirm = (message: string) => {
  const shouldConfirm = window.confirm(message);
  if (window.api?.fixFocus) window.api.fixFocus();

  return shouldConfirm;
};

export const electronToggleDisplay = () => {
  if (window.api?.toggleSecondDisplay) window.api.toggleSecondDisplay();
}

export const electronMinimize = () => {
  if (window.api?.toggleSecondDisplay) window.api.electronActions("minimize");;
}

export const electronClose = () => {
  if (window.api?.electronActions) window.api.electronActions("close");
}
