import { useCurrentOrdersStore, useOrdersStore } from "../store";

import { clampToRange } from "../services/commons";

import { IProductObject } from "../interfaces/productInterfaces";
import { IProduct } from "../interfaces/storeInterfaces";

const useProductsList = (object: IProductObject, maxQuantity: number, product?: IProduct | null, setProducts?: React.Dispatch<React.SetStateAction<IProduct[]>>) => {
  const currentOrderId = useCurrentOrdersStore((state) => state.currentOrderId);
  const order = useOrdersStore((state) => state.fetchOrder(currentOrderId));
  const addProductToOrder = useOrdersStore((state) => state.addProductToOrder);
  const increaseProductQuantity = useOrdersStore((state) => state.increaseProductQuantity);
  const decreaseProductQuantity = useOrdersStore((state) => state.decreaseProductQuantity);
  const changeProductQuantity = useOrdersStore((state) => state.changeProductQuantity);

  const handleAddProductEvent = () => {
    if (!setProducts) return;

    if (product) {
      const newQuantity = product.quantity + 1;
      setProducts((prev) => prev.map((p) => (p.id === product.id ? { ...p, quantity: newQuantity } : p)));
    } else {
      const newProduct: IProduct = newProductObject(1);
      setProducts((prev) => [...prev, newProduct]);
    }
  };

  const handleRemoveProductEvent = () => {
    if (!product || !setProducts) return;

    const isMinimumQuantity = product.quantity <= 1;
    if (isMinimumQuantity) {
      setProducts((prev) => prev.filter((p) => p.id !== product.id));
    } else {
      const newQuantity = product.quantity - 1;
      setProducts((prev) => prev.map((p) => (p.id === product.id ? { ...p, quantity: newQuantity } : p)));
    }
  };
  const handleOnBlurEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!setProducts) return;

    const value = +e.target.value;
    const newQuantity = clampToRange(value, 0, maxQuantity);

    setProducts((prev) => {
      const existingProduct = prev.find((product) => product.id === object.id);
      if (existingProduct && newQuantity !== 0) {
        return prev.map((product) => {
          if (product.id === object.id) {
            return {
              ...product,
              quantity: newQuantity
            };
          }
          return product;
        });
      } else if (newQuantity !== 0) {
        const newProduct: IProduct = newProductObject(newQuantity);
        return [...prev, newProduct];
      } else {
        return [...prev.filter((product) => product.id !== object.id)];
      }
    });
  };

  const handleRemoveProduct = () => {
    if (product && product.quantity > 0) {
      decreaseProductQuantity(object.id);
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const newQuantity = clampToRange(value, 0, maxQuantity);

    if (order && order.products && order.products.find((product) => product.id === object.id)) {
      changeProductQuantity(object.id, newQuantity);
    } else {
      if (newQuantity === 0) return;
      const newProduct: IProduct = newProductObject(newQuantity);
      addProductToOrder(newProduct);
    }
  };

  const handleAddProduct = () => {
    // if there is already an order and this product is in it, increase quantity
    if (order && order.products && order.products.find((product) => product.id === object.id)) {
      increaseProductQuantity(object.id, maxQuantity);
    } else {
      const newProduct: IProduct = newProductObject(1);
      addProductToOrder(newProduct);
    }
  };

  const newProductObject = (quantity: number) => {
    return {
      id: object.id,
      name: object.name,
      price: parseFloat(String(object.price)),
      image: object.picture_url,
      quantity: quantity,
      maxQuantity: maxQuantity,
      productType: object.product_type,
      vat: String(object.vat),
      ean: object.ean
    };
  };
  return { handleAddProductEvent, handleRemoveProductEvent, handleOnBlurEvent, handleOnBlur, handleAddProduct, handleRemoveProduct };
};
export default useProductsList;
