import { baseInstance } from "../../axiosCashier";
import { IchangeStatusFaqContact, IfetchFaqContact, IfetchFaqContacts } from "../../interfaces/faqContactInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/faq_contacts";

export const changeStatusFaqContact = (params: IchangeStatusFaqContact) => {
  return baseInstance.patch(`${BASE_URL}/${params.id}/change_status.json`, { transition_type: params.transitionType });
};

export const fetchFaqContact = (params: IfetchFaqContact) => {
  return baseInstance.get(`${BASE_URL}/${params.id}.json`);
};

export const fetchFaqContacts = (params: IfetchFaqContacts) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};
