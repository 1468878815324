import { useLayoutEffect, useRef, useState } from "react";

import { useBaseStore } from "../store";

import { IAdminTaskObject } from "../interfaces/adminTaskInterfaces";
import { fetchAdminTasks } from "../services/objects/adminTasks.service";
import { handleFetchObjectsResponse } from "../services/responseDataHandler";

const useAdminTasks = (initPerPage: number) => {
  const refresh = useBaseStore((state) => state.refresh);

  const [adminTasks, setAdminTasks] = useState<IAdminTaskObject[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(initPerPage);
  const [totalCount, setTotalCount] = useState<number>(0);
  const name = useRef<string>("");
  const statusScope = useRef<"" | "closed" | "pending" | "started">("");

  useLayoutEffect(() => {
    fetchObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, refresh]);

  const fetchObjects = () => {
    const params = { per_page: perPage, page: page, name: name.current, status: statusScope.current };
    fetchAdminTasks(params).then((response) => {
      handleFetchObjectsResponse({
        response: response,
        setTotalCount: setTotalCount,
        setObjects: setAdminTasks
      });
    });
  };

  return { adminTasks, setAdminTasks, page, setPage, perPage, setPerPage, totalCount, setTotalCount, name, statusScope, fetchObjects };
};
export default useAdminTasks;
