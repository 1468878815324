import { useEffect, useState } from "react";

import { useClickAway } from "../../hooks";

import { IContextMenu } from "../../interfaces/sharedComponentInterfaces";

const ContextMenu = (props: IContextMenu) => {
  const { position, children, onClose } = props;

  const [menuPosition, setMenuPosition] = useState<{ x: number; y: number } | null>(null);
  const ref = useClickAway(onClose);

  useEffect(() => {
    if (!position) return;

    const { x, y } = position;
    const menuRef = ref.current;

    if (!menuRef) return;

    const menuWidth = menuRef.offsetWidth;
    const menuHeight = menuRef.offsetHeight;

    // Calculate maximum positions to keep the menu within the screen
    const maxX = window.innerWidth - menuWidth;
    const maxY = window.innerHeight - menuHeight;

    // Adjust the menu position if it's outside the screen boundaries
    const adjustedX = Math.min(x, maxX);
    const adjustedY = Math.min(y, maxY);

    setMenuPosition({ x: adjustedX, y: adjustedY });
  }, [position, ref]);

  if (!position) return null;

  return (
    <div
      className={`absolute transition-opacity opacity-0 invisible ${menuPosition ? "!opacity-100 !visible" : ""}`}
      style={{ left: menuPosition ? menuPosition.x : 0, top: menuPosition ? menuPosition.y : 0 }}
      ref={ref}>
      {children}
    </div>
  );
};

export default ContextMenu;
