import { ISelect } from "../../interfaces/sharedComponentInterfaces";

const Select = (props: ISelect) => {
  const { children, customClasses, defaultValue, disabled, errors, icon, label, labelFs, onChange, register, testId, value } = props;

  const baseClasses = `block p-2 focus:outline-none disabled:bg-zinc-200 border${icon ? " w-full py-1" : ""}`;

  let errorClasses = "text-gray-900 border border-gray-300 bg-gray-50 focus:border-emerald-500";
  if (errors) {
    errorClasses = "text-red-900 placeholder-red-700 border border-red-500 bg-red-50 focus:border-red-500";
  }

  return (
    <div className="w-full flex flex-col">
      {label && <label className={`${labelFs ? labelFs : "text-xs"} text-left`}>{label}:</label>}
      <div className="flex">
        {icon && <div className="flex items-center justify-center w-10 bg-slate-200 text-slate-700 text-2xl">{icon}</div>}
        <select defaultValue={defaultValue} value={value} onChange={onChange} className={`${baseClasses} ${errorClasses} ${customClasses}`} data-testid={testId} {...register} disabled={disabled}>
          {children}
        </select>
      </div>
      {errors && <p className="text-xs text-red-500">{errors.message ? String(errors.message) : ""}</p>}
    </div>
  );
};

export default Select;
