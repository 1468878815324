import { useBaseStore } from "../store";

import { useLocalStorage } from "./";

import { IprintingMessageObject } from "../interfaces/cashierInterfaces";

export interface IprintingProblems {
  skippedOrders: number[];
  skippedCarnetBases: number[];
  skippedCarnetOrders: number[];
  skippedProductOrders: number[];
  ignoredOrders: number[];
  ignoredCarnetBases: number[];
  ignoredCarnetOrders: number[];
  ignoredProductOrders: number[];
  ignoredTickets: number[];
}

const usePrintingProblems = () => {
  const [currentCashier] = useLocalStorage("current_cashier", null);
  const [value, setValue] = useLocalStorage("printing_problems", {
    [currentCashier.id]: {
      skippedOrders: [],
      skippedCarnetBases: [],
      skippedCarnetOrders: [],
      skippedProductOrders: [],
      ignoredOrders: [],
      ignoredCarnetBases: [],
      ignoredCarnetOrders: [],
      ignoredProductOrders: [],
      ignoredTickets: []
    }
  });
  const printingProblemsOpen = useBaseStore((state) => state.printingProblemsOpen);
  const setPrintingProblemsOpen = useBaseStore((state) => state.setPrintingProblemsOpen);
  const setPrintingProblemsActiveTab = useBaseStore((state) => state.setPrintingProblemsActiveTab);

  const saveActiveCashierPrintingProblems = (deepCopy: IprintingProblems) => {
    setValue({ ...value, [currentCashier.id]: deepCopy });
  };

  const setPrintingProblems = (printingProblems: IprintingMessageObject) => {
    const { carnet_base_ids, carnet_order_ids, order_ids, product_order_ids } = printingProblems;

    let deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));

    const updateIds = (ids: number[], skipped: number[], ignored: number[]) => {
      const newSkipped = skipped.filter((id) => ids.includes(id));
      const newIgnored = ignored.filter((id) => ids.includes(id));
      const newIds = ids.filter((id) => !newSkipped.includes(id) && !newIgnored.includes(id));
      return [...newSkipped, ...newIds];
    };

    const problemTypes: {
      type: "orders" | "carnets" | "productOrders" | "carnetOrders";
      ids: number[];
      skipped: keyof IprintingProblems;
      ignored: keyof IprintingProblems;
    }[] = [
      { type: "orders", ids: order_ids, skipped: "skippedOrders", ignored: "ignoredOrders" },
      { type: "carnets", ids: carnet_base_ids, skipped: "skippedCarnetBases", ignored: "ignoredCarnetBases" },
      { type: "productOrders", ids: product_order_ids, skipped: "skippedProductOrders", ignored: "ignoredProductOrders" },
      { type: "carnetOrders", ids: carnet_order_ids, skipped: "skippedCarnetOrders", ignored: "ignoredCarnetOrders" }
    ];

    problemTypes.forEach(({ ids, skipped, ignored }) => {
      deepCopy = { ...deepCopy, [skipped]: updateIds(ids, deepCopy[skipped], deepCopy[ignored]) };
    });

    saveActiveCashierPrintingProblems(deepCopy);

    const activeTab = problemTypes.find(({ skipped }) => deepCopy[skipped].length > 0)?.type;

    if (activeTab) {
      setPrintingProblemsOpen(true);
      setPrintingProblemsActiveTab(activeTab);
    }
  };

  const getActiveCashierPrintingProblems = () => {
    const problems = value[currentCashier.id];
    if (!problems) {
      return {
        skippedOrders: [],
        skippedCarnetBases: [],
        skippedCarnetOrders: [],
        skippedProductOrders: [],
        ignoredOrders: [],
        ignoredCarnetBases: [],
        ignoredCarnetOrders: [],
        ignoredProductOrders: [],
        ignoredTickets: []
      };
    }
    return problems;
  };

  const handleIgnoreAllProblems = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));

    deepCopy.skippedOrders &&
      deepCopy.skippedOrders.forEach((id) => {
        if (!deepCopy.ignoredOrders.includes(id)) deepCopy.ignoredOrders.push(id);
      });
    deepCopy.skippedCarnetBases &&
      deepCopy.skippedCarnetBases.forEach((id) => {
        if (!deepCopy.ignoredCarnetBases.includes(id)) deepCopy.ignoredCarnetBases.push(id);
      });
    deepCopy.skippedCarnetOrders &&
      deepCopy.skippedCarnetOrders.forEach((id) => {
        if (!deepCopy.ignoredCarnetOrders.includes(id)) deepCopy.ignoredCarnetOrders.push(id);
      });
    deepCopy.skippedProductOrders &&
      deepCopy.skippedProductOrders.forEach((id) => {
        if (!deepCopy.ignoredProductOrders.includes(id)) deepCopy.ignoredProductOrders.push(id);
      });

    deepCopy.skippedOrders = [];
    deepCopy.skippedCarnetBases = [];
    deepCopy.skippedCarnetOrders = [];
    deepCopy.skippedProductOrders = [];
    deepCopy.ignoredTickets = [];

    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleRestoreAllProblems = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredOrders &&
      deepCopy.ignoredOrders.forEach((id) => {
        if (!deepCopy.skippedOrders.includes(id)) deepCopy.skippedOrders.push(id);
      });
    deepCopy.ignoredCarnetBases &&
      deepCopy.ignoredCarnetBases.forEach((id) => {
        if (!deepCopy.skippedCarnetBases.includes(id)) deepCopy.skippedCarnetBases.push(id);
      });
    deepCopy.ignoredCarnetOrders &&
      deepCopy.ignoredCarnetOrders.forEach((id) => {
        if (!deepCopy.skippedCarnetOrders.includes(id)) deepCopy.skippedCarnetOrders.push(id);
      });
    deepCopy.ignoredProductOrders &&
      deepCopy.ignoredProductOrders.forEach((id) => {
        if (!deepCopy.skippedProductOrders.includes(id)) deepCopy.skippedProductOrders.push(id);
      });
    deepCopy.ignoredOrders = [];
    deepCopy.ignoredCarnetBases = [];
    deepCopy.ignoredCarnetOrders = [];
    deepCopy.ignoredProductOrders = [];
    saveActiveCashierPrintingProblems(deepCopy);
    setPrintingProblemsOpen(false);
  };

  const selectNextProblem = (ids: number[], activeId: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const index = ids.indexOf(activeId);
    const nextId = ids[index + 1];
    if (nextId === undefined) {
      setActiveId(ids[0]);
      return;
    }
    setActiveId(nextId);
  };

  const selectPreviousProblem = (ids: number[], activeId: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const index = ids.indexOf(activeId);
    const previousId = ids[index - 1];
    if (previousId === undefined) {
      setActiveId(ids[ids.length - 1]);
      return;
    }
    setActiveId(previousId);
  };

  let timeoutId: NodeJS.Timeout | null = null;

  const rememberLater = (minutes: number) => {
    const ms = 60000 * minutes;
    setPrintingProblemsOpen(false);

    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setPrintingProblemsOpen(true);
      timeoutId = null;
    }, ms);
  };

  const cancelRememberLater = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  const showTopbarButton = () => {
    const printingProblems: IprintingProblems = getActiveCashierPrintingProblems();
    if (!printingProblems) return false;

    if (!printingProblemsOpen) {
      if (printingProblems.skippedOrders.length !== 0 || printingProblems.skippedCarnetBases.length !== 0 || printingProblems.skippedProductOrders.length !== 0) return true;
    }
  };

  const showPrintingProblems = () => {
    const printingProblems: IprintingProblems = getActiveCashierPrintingProblems();
    cancelRememberLater();
    if (printingProblems.skippedOrders.length > 0) setPrintingProblemsActiveTab("orders");
    else if (printingProblems.skippedCarnetBases.length > 0) setPrintingProblemsActiveTab("carnets");
    else if (printingProblems.skippedProductOrders.length > 0) setPrintingProblemsActiveTab("productOrders");
    else if (printingProblems.skippedCarnetOrders.length > 0) setPrintingProblemsActiveTab("carnetOrders");
    setPrintingProblemsOpen(true);
  };

  return {
    setPrintingProblems,
    setPrintingProblemsOpen,
    handleRestoreAllProblems,
    showPrintingProblems,
    getActiveCashierPrintingProblems,
    showTopbarButton,
    selectNextProblem,
    handleIgnoreAllProblems,
    saveActiveCashierPrintingProblems,
    rememberLater,
    selectPreviousProblem,
    setPrintingProblemsActiveTab
  };
};
export default usePrintingProblems;
