import { useTranslation } from "react-i18next";

const NoObjectsPresent = () => {
  const { t } = useTranslation();

  return (
    <div className="font-bold text-center text-2xl text-zinc-600" data-testid="no-objects">
      {t("base.no_objects")}
    </div>
  );
};

export default NoObjectsPresent;
