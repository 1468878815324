import { usePrintingProblems } from "./";

import { IprintingProblems } from "./usePrintingProblems";

const useCarnetBasePrintingProblems = () => {
  const { getActiveCashierPrintingProblems, selectNextProblem, setPrintingProblemsActiveTab, saveActiveCashierPrintingProblems, setPrintingProblemsOpen } = usePrintingProblems();

  const getSkippedCarnetBases = () => {
    return getActiveCashierPrintingProblems().skippedCarnetBases || [];
  };

  const handleIgnoreCarnetBase = (id: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredCarnetBases.push(id);
    selectNextProblem(deepCopy.skippedCarnetBases, id, setActiveId);
    deepCopy.skippedCarnetBases = deepCopy.skippedCarnetBases.filter((carnetBaseId) => carnetBaseId !== id);
    selectNextTabFromCarnets(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleIgnoreCarnetBases = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.skippedCarnetBases.forEach((id) => {
      if (!deepCopy.ignoredCarnetBases.includes(id)) deepCopy.ignoredCarnetBases.push(id);
    });
    deepCopy.skippedCarnetBases = [];
    selectNextTabFromCarnets(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const checkIfCarnetBaseIsSkipped = (id: number) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    return deepCopy.skippedCarnetBases.includes(id);
  };

  const countSkippedCarnetBases = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    return deepCopy.skippedCarnetBases.length;
  };

  const selectNextTabFromCarnets = (deepCopy: IprintingProblems) => {
    const skippedOrders = deepCopy.skippedOrders;
    const skippedCarnetBases = deepCopy.skippedCarnetBases;
    const skippedCarnetOrders = deepCopy.skippedCarnetOrders;
    const skippedProductOrders = deepCopy.skippedProductOrders;

    if (skippedCarnetBases.length !== 0) return;

    if (skippedProductOrders.length !== 0) setPrintingProblemsActiveTab("productOrders");
    else if (skippedCarnetOrders.length !== 0) setPrintingProblemsActiveTab("carnetOrders");
    else if (skippedOrders.length !== 0) setPrintingProblemsActiveTab("orders");
  };

  const removeCarnetBaseProblem = (id: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    selectNextProblem(deepCopy.skippedCarnetBases, id, setActiveId);
    deepCopy.skippedCarnetBases = deepCopy.skippedCarnetBases.filter((carnetBaseId) => carnetBaseId !== id);
    deepCopy.ignoredCarnetBases = deepCopy.ignoredCarnetBases.filter((carnetBaseId) => carnetBaseId !== id);
    selectNextTabFromCarnets(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleRestoreAllCarnetBases = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredCarnetBases.forEach((id) => {
      if (!deepCopy.skippedCarnetBases.includes(id)) deepCopy.skippedCarnetBases.push(id);
    });
    deepCopy.ignoredCarnetBases = [];
    saveActiveCashierPrintingProblems(deepCopy);
    setPrintingProblemsOpen(false);
  };

  return {
    checkIfCarnetBaseIsSkipped,
    countSkippedCarnetBases,
    getSkippedCarnetBases,
    handleIgnoreCarnetBase,
    handleIgnoreCarnetBases,
    removeCarnetBaseProblem,
    handleRestoreAllCarnetBases
  };
};
export default useCarnetBasePrintingProblems;
