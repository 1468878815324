import { useTranslation } from "react-i18next";

const useTaskStatus = (status: string, type: "block" | "text", classes?: string): JSX.Element => {
  const { t } = useTranslation();

  if (type === "text") {
    const statusMap: Record<string, JSX.Element> = {
      started: (
        <p className="text-emerald-400 font-semibold" data-testid="started">
          {t("admin_task.statuses.started")}
        </p>
      ),
      closed: (
        <p className="text-red-300 font-semibold" data-testid="closed">
          {t("admin_task.statuses.closed")}
        </p>
      ),
      pending: (
        <p className="text-yellow-500 font-semibold" data-testid="pending">
          {t("admin_task.statuses.pending")}
        </p>
      )
    };
    return statusMap[status];
  }
  const statusMap: Record<string, JSX.Element> = {
    started: (
      <p className={`${classes ? classes : "px-4 py-2 rounded-lg"} bg-emerald-400`} data-testid="started">
        {t("admin_task.statuses.started")}
      </p>
    ),
    closed: (
      <p className={`${classes ? classes : "px-4 py-2 rounded-lg"} bg-red-300`} data-testid="closed">
        {t("admin_task.statuses.closed")}
      </p>
    ),
    pending: (
      <p className={`${classes ? classes : "px-4 py-2 rounded-lg"} bg-yellow-400`} data-testid="pending">
        {t("admin_task.statuses.pending")}
      </p>
    )
  };

  return statusMap[status] || null;
};

export default useTaskStatus;
