import { baseInstance } from "../../axiosCashier";
import {
  IcomplainProductOrder,
  IconfirmProductOrder,
  IcreateProductOrder,
  IdestroyProductOrder,
  IfetchProductOrder,
  IfetchProductOrderForPosNewInvoice,
  IfetchProductOrders,
  IfetchProductOrdersForPosNewInvoice,
  IprintProductOrder
} from "../../interfaces/productOrderInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/product_orders";

export const complainProductOrder = (params: IcomplainProductOrder) => {
  const complainParams = fetchQueryParams(params, ["id", "pos_line_item_id"]);
  return baseInstance.patch(`${BASE_URL}/${params.id}/complain.json`, complainParams);
};

export const confirmProductOrder = (params: IconfirmProductOrder) => {
  const queryParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/confirm.json`, queryParams);
};

export const createProductOrder = (params: IcreateProductOrder) => {
  return baseInstance.post(`${BASE_URL}.json`, params);
};

export const destroyProductOrder = (params: IdestroyProductOrder) => {
  return baseInstance.delete(`${BASE_URL}/${params.id}.json`);
};

export const fetchProductOrder = (params: IfetchProductOrder) => {
  const queryParams = fetchQueryParams(params, ["id", "order_id"]);
  return baseInstance.get(`${BASE_URL}/show_object.json${queryParams}`);
};

export const fetchProductOrderForPosNewInvoice = (params: IfetchProductOrderForPosNewInvoice) => {
  return baseInstance.get(`${BASE_URL}/${params.id}/show_for_pos_new_invoice.json`);
};

export const fetchProductOrders = (params: IfetchProductOrders) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};

export const fetchProductOrdersForPosNewInvoice = (params: IfetchProductOrdersForPosNewInvoice) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}/index_for_pos_new_invoice.json${queryParams}`);
};

export const printProductOrder = (params: IprintProductOrder) => {
  return baseInstance.patch(`${BASE_URL}/${params.id}/print.json`);
};
