import { baseInstance } from "../../axiosCashier";
import { IcomplainTickets, IcomplainAllTickets, IfetchTickets, IprintTickets, IprintAllTickets, IvalidateTickets, IvalidateAllTickets } from "../../interfaces/ticketInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/tickets";

export const complainTickets = (params: IcomplainTickets) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.patch(`${BASE_URL}/complain.json${queryParams}`);
};

export const complainAllTickets = (params: IcomplainAllTickets) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.patch(`${BASE_URL}/complain_all.json${queryParams}`);
};

export const fetchTickets = (params: IfetchTickets) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};

export const printTickets = (params: IprintTickets) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.patch(`${BASE_URL}/print.json${queryParams}`);
};

export const printAllTickets = (params: IprintAllTickets) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.patch(`${BASE_URL}/print_all.json${queryParams}`);
};

export const validateTickets = (params: IvalidateTickets) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.patch(`${BASE_URL}/validate.json${queryParams}`);
};

export const validateAllTickets = (params: IvalidateAllTickets) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.patch(`${BASE_URL}/validate_all.json${queryParams}`);
};
