import { useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import { useBaseStore } from "../store";

import { complainAllTickets, complainTickets, validateAllTickets, validateTickets } from "../services/objects/tickets.service";
import { handlePatchResponse } from "../services/responseDataHandler";

import { IOrderObject } from "../interfaces/orderInterfaces";
import { electronAlert, electronConfirm } from "../services/electronWorkarounds";

const useTicket = () => {
  const toggleRefresh = useBaseStore((state) => state.toggleRefresh);
  const setPrintLoader = useBaseStore((state) => state.setPrintLoader);

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const complainAll = (order: IOrderObject) => {
    if (!electronConfirm(t("order.complaint_all_confirm"))) return;

    const params = {
      all_sold: true,
      order_id: order.id
    };
    complainAllTickets(params).then((res) => {
      setLoading(true);
      handlePatchResponse({
        response: res,
        successCallback: toggleRefresh,
        setLoading: setLoading,
        successMessage: t("order.complaint_all_success"),
        failureMessage: t("order.complaint_all_failure"),
        enqueueSnackbar: enqueueSnackbar
      });
    });
  };

  const printAll = (order: IOrderObject) => {
    if (!electronConfirm(t("order.print_all_confirm"))) return;

    // TODO
    electronAlert("TODO");
  };

  const validateAll = (order: IOrderObject) => {
    if (!electronConfirm(t("order.validate_all_confirm"))) return;

    const params = {
      all_sold: true,
      order_id: order.id
    };
    validateAllTickets(params).then((res) => {
      setLoading(true);
      handlePatchResponse({
        response: res,
        successCallback: toggleRefresh,
        setLoading: setLoading,
        successMessage: t("order.validate_all_success"),
        failureMessage: t("order.validate_all_failure"),
        enqueueSnackbar: enqueueSnackbar
      });
    });
  };

  const complain = (ids: number[]) => {
    if (!electronConfirm(t("order.complaint_confirmation"))) return;

    complainTickets({ ids }).then((res) => {
      setLoading(true);
      handlePatchResponse({
        response: res,
        successCallback: toggleRefresh,
        setError: () => {},
        setLoading: setLoading,
        successMessage: t("order.complaint_success"),
        failureMessage: t("order.complaint_failure"),
        enqueueSnackbar: enqueueSnackbar
      });
    });
  };

  const print = (ids: number[]) => {
    if (!electronConfirm(t("order.print_confirmation"))) return;

    // printTickets({ ids }).then((res) => {
    //   setLoading(true);
    //   handlePatchResponse({
    //     response: res,
    //     successCallback: successCallback,
    //     setError: () => {},
    //     setLoading: setLoading,
    //     successMessage: t("order.print_success"),
    //     failureMessage: t("order.print_failure"),
    //     enqueueSnackbar: enqueueSnackbar
    //   });
    // });
  };

  const nonFiscalPrint = (order: IOrderObject, ticketIds?: number[]) => {
    if (!electronConfirm(t("base.non_fiscal_print_confirm"))) return;

    setPrintLoader({ order: order, ticketIds: ticketIds, fiscal: false, nonFiscal: true });
  };

  const validate = (ids: number[]) => {
    if (!electronConfirm(t("order.validate_confirmation"))) return;

    validateTickets({ ids }).then((res) => {
      setLoading(true);
      handlePatchResponse({
        response: res,
        successCallback: toggleRefresh,
        setError: () => {},
        setLoading: setLoading,
        successMessage: t("order.validate_success"),
        failureMessage: t("order.validate_failure"),
        enqueueSnackbar: enqueueSnackbar
      });
    });
  };

  return { complainAll, complain, loading, nonFiscalPrint, setLoading, printAll, print, validateAll, validate };
};
export default useTicket;
