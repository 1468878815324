import { enqueueSnackbar } from "notistack";

import fiscalNonFiscalPrinter from "./fiscalNonFiscalPrinter.service";

import { IterminalAdminAction, IterminalAnswer, IterminalPay, IterminalPrintCopy, IterminalPrintData, IterminalQuestionData, IterminalSettings } from "../interfaces/terminalInterfaces";
import { t } from "i18next";
import { IfiscalPrinterSettings } from "../interfaces/printerInterfaces";

// Workaround for window.api property not being recognized by Typescript
declare global {
  interface Window {
    api: any;
  }
}

// TODO check origin on production?
export const handleTerminalPostMessage = (
  event: MessageEvent<any>,
  fiscalPrinterSettings: IfiscalPrinterSettings,
  setTerminalQuestionData: (questionData: IterminalQuestionData | null) => void,
  terminalSettings: IterminalSettings
) => {
  // const { data, isTrusted, origin, source, type } = event;
  const { data, isTrusted, source, type } = event;

  // console.log("origin: ", origin);
  if (!isTrusted || source !== window) return;
  if (!data || !data.data || type !== "message") return;
  switch (data.posEvent) {
    case "info":
      handleTerminalInfo(data, terminalSettings);
      break;
    case "print":
      handleTerminalPrint(data.data as string, fiscalPrinterSettings);
      break;
    case "question":
      handleTerminalQuestion(data, setTerminalQuestionData);
      break;
    case "statusUpdate":
      handleTerminalStatusUpdate(data, t);
      break;
    // default:
    //   console.log("Unknown terminal event: ", data);
  }
};

const handleTerminalInfo = (data: any, terminalSettings: IterminalSettings) => {
  if (!data.data.info) return;

  if (data.data.info.includes("ROZLICZENIE")) {
    terminalAnswer({ answerData: { answer: 2, controlSum: data.data.controlSum }, terminalSettings: terminalSettings });
    enqueueSnackbar(data.data.info, { variant: "success", autoHideDuration: 5000, preventDuplicate: true });
  } else {
    enqueueSnackbar(data.data.info, { variant: "info", autoHideDuration: 5000, preventDuplicate: true });
  }
};

const handleTerminalPrint = (data: string, fiscalPrinterSettings: IfiscalPrinterSettings) => {
  const parsedData = data.split(";").reduce((acc: Record<string, string | string[]>, curr) => {
    const [key, value] = curr.split("#");

    if (!key || !value) return acc;

    if (key.includes("List")) {
      acc[key] = acc[key] ? [...(acc[key] as string[]), value] : [value];
    } else {
      acc[key] = value;
    }

    return acc;
  }, {}) as any;
  if (parsedData.InvAddText){
    setTimeout(() => {
      fiscalNonFiscalPrinter({ printerSettings: fiscalPrinterSettings, t: t, terminalPrintData: parsedData as IterminalPrintData });
    }, 1000);
  } else {
    fiscalNonFiscalPrinter({ printerSettings: fiscalPrinterSettings, t: t, terminalPrintData: parsedData as IterminalPrintData });
  }
  // setTimeout(() => {
  //   fiscalNonFiscalPrinter({ printerSettings: fiscalPrinterSettings, t: t, terminalPrintData: parsedData as IterminalPrintData });
  // }, FISCAL_NON_FISCAL_PRINT_SLEEP);
  // fiscalNonFiscalPrinter({ printerSettings: fiscalPrinterSettings, t: t, terminalPrintData: parsedData as IterminalPrintData });
};

const handleTerminalQuestion = async (data: any, setTerminalQuestionData: (questionData: IterminalQuestionData | null) => void) => {
  if (!data.data) return;

  setTerminalQuestionData(data.data as IterminalQuestionData);
};

const handleTerminalStatusUpdate = (data: any, t: any) => {
  const errorCodes = [150, 180, 915, 925, 928, 929, 930, 933];
  const neutralCodes = [20, 30, 40, 50, 60, 65, 70, 80, 90, 100, 101, 102, 110, 120, 130, 155, 190, 916, 932];
  const successCodes = [140, 923];
  let message = "";
  let messageType: "default" | "error" | "success" | "warning" | "info" | undefined = undefined;

  if (successCodes.indexOf(data.data.code) !== -1) {
    message = t(`terminal.status_updates.${data.data.code}`);
    messageType = "success";
  } else if (neutralCodes.indexOf(data.data.code) !== -1) {
    message = t(`terminal.status_updates.${data.data.code}`);
    messageType = "info";
  } else if (errorCodes.indexOf(data.data.code) !== -1) {
    message = t(`terminal.status_updates.${data.data.code}`);
    messageType = "error";
  } else {
    message = data.data.text;
    messageType = "warning";
  }

  if (message && messageType) {
    enqueueSnackbar(message, { variant: messageType, autoHideDuration: 5000, preventDuplicate: true });
  }
};

export const listTerminals = async () => {
  if (!window.api?.posTerminalActions) return false;

  try {
    const response = await window.api.posTerminalActions("posListTerminals");
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const pingTerminal = async () => {
  if (!window.api?.posTerminalActions) return false;

  try {
    const response = await window.api.posTerminalActions("posPingTerminalRequest");
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const terminalAdminAction = async (props: IterminalAdminAction) => {
  if (!window.api?.posTerminalActions) return false;

  const { actionType, terminalSettings } = props;
  try {
    const response = await window.api.posTerminalActions("posPaymentTerminalAdminActionRequest", { terminalRequest: { actionType: actionType }, terminalSettings: terminalSettings });
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const terminalAnswer = async (props: IterminalAnswer) => {
  if (!window.api?.posTerminalActions) return false;

  const { answerData, terminalSettings } = props;
  try {
    const response = await window.api.posTerminalActions("posPaymentTerminalAnswerRequest", { terminalRequest: answerData, terminalSettings: terminalSettings });
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const terminalPay = async (props: IterminalPay) => {
  if (!window.api?.posTerminalActions) return { result: -1 };

  const { payData, terminalSettings } = props;
  try {
    const response = await window.api.posTerminalActions("posPaymentTerminalRequest", { terminalRequest: payData, terminalSettings: terminalSettings });
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return { data: {}, exitStatus: 1, result: -1 };
  }
};

export const terminalPrintCopy = async (props: IterminalPrintCopy) => {
  if (!window.api?.posTerminalActions) return false;

  const { requestData, terminalSettings } = props;
  try {
    const response = await window.api.posTerminalActions("posPaymentTerminalPrintCopyRequest", { terminalRequest: requestData, terminalSettings: terminalSettings });
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const terminalUnlock = async (props: { terminalSettings: IterminalSettings }) => {
  if (!window.api?.posTerminalActions) return false;

  const { terminalSettings } = props;
  try {
    const response = await window.api.posTerminalActions("posPaymentTerminalCancelRequest", { terminalRequest: { actionType: "P1" }, terminalSettings: terminalSettings });
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};
