import { useCurrentOrdersStore } from "../../store";
import { IOrder, IOrderStore, IProduct } from "../../interfaces/storeInterfaces";

export const addProductToOrder = (state: IOrderStore, product: IProduct) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  // Add new order if there is no current order
  console.log("currentOrdersStore.currentOrderId: ", currentOrdersStore.currentOrderId);
  console.log("order: ", order);
  if (currentOrdersStore.currentOrderId === -1 || order === undefined) {
    const orderId = Date.now();
    useCurrentOrdersStore.setState((state) => {
      state.setCurrentOrderId(orderId);
      return { ...state, currentOrderId: orderId };
    });
    const order: IOrder = {
      id: orderId,
      products: [product],
      reservationId: undefined,
      reservationSeats: undefined,
      activities: [],
      sectors: [],
      seats: [],
      selectedSeats: [],
      voucher: undefined
    };
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    updatedOrders.push(order);
    return { orders: updatedOrders };
  }

  // Adding product to existing order
  if (order) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    updatedOrders[index].products.push(product);
    return { orders: updatedOrders };
  }

  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  return { orders: updatedOrders };
};

export const addProductsToOrder = (state: IOrderStore, products: IProduct[]) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  // Add new order if there is no current order
  if (currentOrdersStore.currentOrderId === -1 || order === undefined) {
    const orderId = Date.now();
    useCurrentOrdersStore.setState((state) => {
      state.setCurrentOrderId(orderId);
      return { ...state, currentOrderId: orderId };
    });
    const order: IOrder = {
      id: orderId,
      products: products,
      reservationId: undefined,
      reservationSeats: undefined,
      activities: [],
      sectors: [],
      seats: [],
      selectedSeats: [],
      voucher: undefined
    };
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    updatedOrders.push(order);
    return { orders: updatedOrders };
  }

  // Adding product to existing order
  if (order) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    updatedOrders[index].products = products;
    return { orders: updatedOrders };
  }

  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  return { orders: updatedOrders };
};

export const changeProductQuantity = (state: IOrderStore, productId: number, quantity: number) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.products.length) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const products = updatedOrders[index].products;
    const productIndex = updatedOrders[index].products.findIndex((product: IProduct) => product.id === productId);
    if (productIndex !== undefined && productIndex !== -1) {
      updatedOrders[index].products[productIndex].quantity = quantity;
    }
    // remove product if quantity is 0
    if (products[productIndex].quantity === 0) {
      products.splice(productIndex, 1);
    }
    // remove order if there are no products / sectors / seats / activities / voucher
    if (
      products.length === 0 &&
      updatedOrders[index].sectors.length === 0 &&
      updatedOrders[index].selectedSeats.length === 0 &&
      updatedOrders[index].activities.length === 0 &&
      updatedOrders[index].voucher === undefined
    ) {
      updatedOrders.splice(index, 1);
      useCurrentOrdersStore.setState((state) => {
        state.removeCurrentOrderId();
        return { ...state, currentOrderId: -1 };
      });
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

export const decreaseProductQuantity = (state: IOrderStore, productId: number) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.products.length) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const products = updatedOrders[index].products;
    const productIndex = products.findIndex((product: IProduct) => product.id === productId);

    if (productIndex !== undefined && productIndex !== -1) {
      const isMinimumQuantity = products[productIndex] && products[productIndex].quantity <= 0;
      if (!isMinimumQuantity) products[productIndex].quantity--;
      // remove product if quantity is 0
      if (products[productIndex].quantity === 0) {
        products.splice(productIndex, 1);
      }
      // remove order if there are no products / sectors / seats / activities / voucher
      if (
        products.length === 0 &&
        updatedOrders[index].sectors.length === 0 &&
        updatedOrders[index].selectedSeats.length === 0 &&
        updatedOrders[index].activities.length === 0 &&
        updatedOrders[index].voucher === undefined
      ) {
        updatedOrders.splice(index, 1);
        useCurrentOrdersStore.setState((state) => {
          state.removeCurrentOrderId();
          return { ...state, currentOrderId: -1 };
        });
      }
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

export const increaseProductQuantity = (state: IOrderStore, productId: number, maxQuantity: number) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.products.length) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const productIndex = updatedOrders[index].products.findIndex((product: IProduct) => product.id === productId);
    if (productIndex !== undefined && productIndex !== -1) {
      const product = updatedOrders[index].products[productIndex];
      const isMaximumQuantity = product && product.quantity >= maxQuantity;
      if (!isMaximumQuantity) product.quantity++;
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

export const removeProductFromOrder = (state: IOrderStore, productId: number) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.products.length) {
    const updatedOrders = [...state.orders];
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const productIndex = updatedOrders[index].products.findIndex((product: IProduct) => product.id === productId);
    if (productIndex !== undefined && productIndex !== -1) {
      updatedOrders[index].products.splice(productIndex, 1);
      // remove order if there are no products / sectors / seats / activities / voucher
      if (
        updatedOrders[index].products.length === 0 &&
        updatedOrders[index].sectors.length === 0 &&
        updatedOrders[index].selectedSeats.length === 0 &&
        updatedOrders[index].activities.length === 0 &&
        updatedOrders[index].voucher === undefined
      ) {
        updatedOrders.splice(index, 1);
        useCurrentOrdersStore.setState((state) => {
          state.removeCurrentOrderId();
          return { ...state, currentOrderId: -1 };
        });
      }
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};
