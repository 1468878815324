import { useEffect, useState } from "react";

import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import { Button, WithShadow } from "./";

import { currencyFormat } from "../../services/commons";
import { IQuantityTile } from "../../interfaces/sharedComponentInterfaces";
import { Tooltip } from "react-tooltip";

const QuantityTile = (props: IQuantityTile) => {
  const { handleAdd, handleOnBlur, handleRemove, isMaxQuantity, name, price, quantity } = props;

  const [value, setValue] = useState<string>(String(quantity));

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
    setValue(sanitizedValue);
  };

  useEffect(() => {
    setValue(String(quantity));
  }, [quantity]);

  return (
    <WithShadow customClasses="justify-between items-center w-full">
      <div 
          className="px-2 font-bold truncate text-elipsis"
          data-tooltip-id={"fullTicketName"}
          data-tooltip-content={name}
      >
        {name}
      </div>
      
      <div className="flex items-center">
        <div className="font-bold pr-2 whitespace-nowrap">{currencyFormat(price)}</div>
        <Button buttonStyle="error" onClick={handleRemove} customClasses="w-12 h-12 min-w-[48px]" disabled={!quantity}>
          <AiOutlineMinus />
        </Button>
        <input
          className="border-2 border-transparent w-12 h-12 flex justify-center items-center font-bold text-center bg-transparent py-2 focus:outline-0 focus:border-blue-400 transition-colors"
          value={value}
          onChange={handleValueChange}
          onBlur={handleOnBlur}
        />
        <Button buttonStyle="success" onClick={handleAdd} customClasses="w-12 h-12 min-w-[48px]" disabled={isMaxQuantity}>
          <AiOutlinePlus />
        </Button>
      </div>
      <Tooltip id="fullTicketName" />
    </WithShadow>
    
  );

};

export default QuantityTile;
