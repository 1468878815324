import { useTranslation } from "react-i18next";

import { ITabButton } from "../../interfaces/sharedComponentInterfaces";

const TabButton = <T extends string>({ condition, activeTab, tabName, handleTabChange, translationKey }: ITabButton<T>) => {
  const { t } = useTranslation();

  if (!condition) return null;

  const isActive = activeTab === tabName;
  const className = `px-4 py-2 ${isActive ? "bg-zinc-300 font-semibold" : "bg-zinc-200"}`;
  return (
    <button className={className} onClick={() => handleTabChange(tabName)}>
      {t(translationKey)}
    </button>
  );
};

export default TabButton;
