import { useTranslation } from "react-i18next";

const usePaymentType = (paymentType: string | null) => {
  const { t } = useTranslation();

  if (!paymentType) return t("order_summary.confirmation_types.no_data");

  const paymentTypeMap: Record<string, string> = {
    card: t("order_summary.confirmation_types.card"),
    cash: t("order_summary.confirmation_types.cash"),
    other: t("order_summary.confirmation_types.other")
  };
  const paymentTypeText = paymentTypeMap[paymentType] || paymentType;
  return paymentTypeText || t("order_summary.confirmation_types.no_data");
};
export default usePaymentType;
