import { AiOutlinePicture } from "react-icons/ai";

import { ITileImage } from "../../interfaces/sharedComponentInterfaces";
import { imageUrl } from "../../services/commons";

const TileImage = (props: ITileImage) => {
  const { pictureUrl, onPictureClick } = props;

  return pictureUrl === null ? (
    <div className={`bg-zinc-200 w-full h-44 flex items-center justify-center ${onPictureClick && "cursor-pointer"}`} onClick={onPictureClick} data-testid="no-picture">
      <div className="text-9xl text-white">
        <AiOutlinePicture />
      </div>
    </div>
  ) : (
    <div className={`bg-cover w-full h-44 ${onPictureClick && "cursor-pointer"}`} style={{ backgroundImage: `url(${imageUrl(pictureUrl)})` }} onClick={onPictureClick} data-testid="image-tile" />
  );
};

export default TileImage;
