import parse from "html-react-parser";
import DOMPurify from "dompurify";

const useHtmlFormattedText = (description: string) => {
  const sanitized = DOMPurify.sanitize(description, {
    ALLOWED_TAGS: ["b", "i", "em", "strong", "a", "p", "br"],
    ALLOWED_ATTR: ["href"]
  });

  const parsed = parse(sanitized);

  return parsed;
};
export default useHtmlFormattedText;
