import { useRef } from "react";

import { IoIosArrowDown } from "react-icons/io";

import { useClickAway } from "../../hooks";

import { IDropdown } from "../../interfaces/sharedComponentInterfaces";

const Dropdown = (props: IDropdown) => {
  const { children, disabled, open, setOpen, title } = props;

  const handleClick = () => {
    setOpen((oldOpen) => !oldOpen);
  };

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useClickAway(() => setOpen(false), buttonRef);

  return (
    <div className="relative inline-block text-left" data-testid="dropdown-wrapper">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-zinc-500 px-3 py-2 text-sm font-semibold text-zinc-200 shadow-sm hover:bg-zinc-400 transition-colors"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={handleClick}
          data-testid="dropdown"
          ref={buttonRef}
          disabled={disabled}>
          {title}
          <IoIosArrowDown className="-mr-1 h-5 w-5 text-zinc-200" fill="currentColor" aria-hidden="true" />
        </button>
      </div>
      {open && (
        <div
          className="absolute left-0 z-10 mt-2 origin-top-right bg-zinc-500 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
          ref={dropdownRef}
          data-testid="dropdown-open">
          <div role="none">{children}</div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
