import { useState } from "react";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import { IInput } from "../../interfaces/sharedComponentInterfaces";

const Input = (props: IInput) => {
  const {
    autoFocus,
    customClasses,
    defaultValue,
    disabled,
    errors,
    hideErrors,
    icon,
    label,
    labelFs,
    max,
    min,
    onChange,
    onInput,
    onKeyDown,
    pattern,
    placeholder,
    register,
    step,
    testId,
    type,
    value
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const baseClasses = `block p-2 focus:outline-none disabled:bg-zinc-200 border${icon || type === "password" ? " w-full" : ""}${icon ? " py-1" : ""}`;

  let errorClasses = "text-gray-900 placeholder-zinc-800 border-gray-300 bg-gray-50 focus:border-emerald-500";
  if (errors) {
    errorClasses = "text-red-900 placeholder-red-700 border-red-500 bg-red-50 focus:border-red-500";
  }

  return (
    <div className="flex flex-col w-full">
      {label && <label className={`${labelFs ? labelFs : "text-xs"} font-medium text-left`}>{label}:</label>}
      <div className="flex relative">
        {icon && <div className="flex items-center justify-center w-10 bg-slate-200 text-slate-700 text-2xl">{icon}</div>}
        <input
          type={type ? (type === "password" && showPassword ? "text" : type) : "text"}
          min={min}
          max={max}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          step={step}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onInput={onInput}
          pattern={pattern}
          placeholder={placeholder}
          autoFocus={autoFocus}
          data-testid={testId}
          className={`${baseClasses} ${errorClasses} ${customClasses}`}
          {...register}
        />
        {type === "password" ? (
          showPassword ? (
            <AiFillEye size="25" className="absolute text-zinc-700 top-1/2 -translate-y-1/2 right-2 cursor-pointer" onClick={() => setShowPassword(false)} />
          ) : (
            <AiFillEyeInvisible size="25" className="absolute text-zinc-700 top-1/2 -translate-y-1/2 right-2 cursor-pointer" onClick={() => setShowPassword(true)} />
          )
        ) : null}
      </div>
      {!hideErrors && errors && <p className="text-xs text-red-500">{errors.message ? String(errors.message) : ""}</p>}
    </div>
  );
};

export default Input;
