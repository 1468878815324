import { useTranslation } from "react-i18next";

const useStandUserStatus = (status: string): JSX.Element => {
  const { t } = useTranslation();

  let bgColors = "bg-emerald-500 hover:bg-emerald-600";
  let textColor = "text-slate-100";
  switch (status) {
    case "archived":
      bgColors = "bg-yellow-400 hover:bg-yellow-500";
      textColor = "text-slate-800";
      break;
    case "blocked":
      bgColors = "bg-red-500 hover:bg-red-600";
      break;
    case "inactive":
      bgColors = "bg-slate-200 hover:bg-slate-300";
      textColor = "text-slate-800";
      break;
  }

  return (
    <p className={`rounded-full p-1 px-2 font-bold cursor-default ${bgColors} ${textColor}`} data-testid={status}>
      {t(`stand_user.statuses.${status}`)}
    </p>
  );
};

export default useStandUserStatus;
