import { baseInstance } from "../../axiosCashier";
import { IchangePassword, IchangePasswordOther, IfetchCashiers, IfetchLogotype, IlogoutCashier, IpurchaseInfo, IupdateCashier, IwakeUp } from "../../interfaces/cashierInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/cashiers";
const OWN_URL = "/info";

export const changePassword = (params: IchangePassword) => {
  return baseInstance.patch(`${OWN_URL}/change_password.json`, { cashier: params });
};

export const changePasswordOther = (params: IchangePasswordOther) => {
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}.json`, { cashier: filteredParams });
};

export const cashierPrintingProblems = () => {
  return baseInstance.get(`${OWN_URL}/printing_problems.json`);
};

export const fetchCashiers = (params: IfetchCashiers) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};

export const fetchCashInfo = () => {
  return baseInstance.get(`${OWN_URL}/cashinfo.json`);
};

export const getCashierInfo = () => {
  return baseInstance.get(`${OWN_URL}/show.json`);
};

// export const getCashierLogotypes = (params: IfetchLogotype) => {
//   const queryParams = fetchQueryParams(params);
//   const responseType = "blob";
//   return baseInstance.get(`${OWN_URL}/logotype.json${queryParams}`, { responseType: responseType });
// };

export  function getCashierLogotypes(params: IfetchLogotype) {
    const response =  baseInstance.get(`${OWN_URL}/logotype.json`, {
      params: params,
      responseType: 'blob', // This is important to get the binary data
      headers: {
        'Accept': 'image/jpeg'
      }});
    return response;
}

export const logoutCashier = (params: IlogoutCashier) => {
  return baseInstance.patch(`${BASE_URL}/${params.id}/logout.json`);
};

export const purchaseInfo = (params: IpurchaseInfo) => {
  const queryParams = fetchQueryParams(params, ["event_id", "hall_id", "view_id"]);
  return baseInstance.get(`${OWN_URL}/purchase_info.json${queryParams}`);
};

export const updateCashier = (params: IupdateCashier) => {
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}.json`, { cashier: filteredParams });
};

export const wakeUp = (params: IwakeUp) => {
  return baseInstance.post(`${OWN_URL}/wake_up.json`, params);
};
