import { useTranslation } from "react-i18next";

const useTransactionType = (type: string | null) => {
  const { t } = useTranslation();

  if (!type) return t("base.null");

  const typeMap: Record<string, string> = {
    carnet: t("dashboard.drawer_balances_list.types.carnet"),
    carnet_order: t("dashboard.drawer_balances_list.types.carnet_order"),
    cashbox_manual_in: t("dashboard.drawer_balances_list.types.deposit"),
    cashbox_manual_out: t("dashboard.drawer_balances_list.types.withdrawal"),
    complained_order: t("dashboard.drawer_balances_list.types.complained_order"),
    order: t("dashboard.drawer_balances_list.types.order"),
    product_order: t("dashboard.drawer_balances_list.types.product_order"),
    terminal_manual_in: t("dashboard.drawer_balances_list.types.deposit"),
    terminal_manual_out: t("dashboard.drawer_balances_list.types.withdrawal")
  };
  return typeMap[type] || t("base.null");
};
export default useTransactionType;
