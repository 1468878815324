import { usePrintingProblems } from "./";

import { IprintingProblems } from "./usePrintingProblems";

const useCarnetOrderPrintingProblems = () => {
  const { getActiveCashierPrintingProblems, selectNextProblem, saveActiveCashierPrintingProblems, setPrintingProblemsActiveTab, setPrintingProblemsOpen } = usePrintingProblems();

  const countSkippedCarnetOrders = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    return deepCopy.skippedCarnetOrders.length;
  };

  const getSkippedCarnetOrders = () => {
    return getActiveCashierPrintingProblems().skippedCarnetOrders || [];
  };

  const handleIgnoreCarnetOrder = (id: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredCarnetOrders.push(id);
    selectNextProblem(deepCopy.skippedCarnetOrders, id, setActiveId);
    deepCopy.skippedCarnetOrders = deepCopy.skippedCarnetOrders.filter((carnetOrderId) => carnetOrderId !== id);
    selectNextTabFromCarnetOrders(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleIgnoreCarnetOrders = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.skippedCarnetOrders.forEach((id) => {
      if (!deepCopy.ignoredCarnetOrders.includes(id)) deepCopy.ignoredCarnetOrders.push(id);
    });
    deepCopy.skippedCarnetOrders = [];
    selectNextTabFromCarnetOrders(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const selectNextTabFromCarnetOrders = (deepCopy: IprintingProblems) => {
    const skippedOrders = deepCopy.skippedOrders;
    const skippedCarnetBases = deepCopy.skippedCarnetBases;
    const skippedCarnetOrders = deepCopy.skippedCarnetOrders;
    const skippedProductOrders = deepCopy.skippedProductOrders;

    if (skippedCarnetOrders.length !== 0) return;

    if (skippedOrders.length !== 0) setPrintingProblemsActiveTab("orders");
    else if (skippedCarnetBases.length !== 0) setPrintingProblemsActiveTab("carnets");
    else if (skippedProductOrders.length !== 0) setPrintingProblemsActiveTab("productOrders");
  };

  const removeCarnetOrderProblem = (id: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    selectNextProblem(deepCopy.skippedCarnetOrders, id, setActiveId);
    deepCopy.skippedCarnetOrders = deepCopy.skippedCarnetOrders.filter((carnetOrderId) => carnetOrderId !== id);
    deepCopy.ignoredCarnetOrders = deepCopy.ignoredCarnetOrders.filter((carnetOrderId) => carnetOrderId !== id);
    selectNextTabFromCarnetOrders(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleRestoreAllCarnetOrders = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredCarnetOrders.forEach((id) => {
      if (!deepCopy.skippedCarnetOrders.includes(id)) deepCopy.skippedCarnetOrders.push(id);
    });
    deepCopy.ignoredCarnetOrders = [];
    saveActiveCashierPrintingProblems(deepCopy);
    setPrintingProblemsOpen(false);
  };

  return {
    countSkippedCarnetOrders,
    getSkippedCarnetOrders,
    handleIgnoreCarnetOrder,
    handleIgnoreCarnetOrders,
    selectNextTabFromCarnetOrders,
    removeCarnetOrderProblem,
    handleRestoreAllCarnetOrders
  };
};
export default useCarnetOrderPrintingProblems;
