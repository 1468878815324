import { INotificationDot } from "../../interfaces/sharedComponentInterfaces";

const NotificationDot = (props: INotificationDot) => {
  const { quantity } = props;

  if (!quantity) return null;

  return (
    <div className="bg-yellow-400 absolute text-sm rounded-full -right-2 -top-2 h-5 w-5 flex justify-center items-center text-zinc-900 z-10">
      {quantity < 10 ? (
        <p>{quantity}</p>
      ) : (
        <div className="flex items-center justify-center">
          <p>9</p> <p style={{ fontSize: 10 }}>+</p>
        </div>
      )}
    </div>
  );
};
export default NotificationDot;
