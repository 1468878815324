import { baseInstance } from "../../axiosCashier";
import { IcreateAdminTask, IfetchAdminTask, IfetchAdminTasks } from "../../interfaces/adminTaskInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/admin_tasks";

export const createAdminTask = (params: IcreateAdminTask) => {
  return baseInstance.post(`${BASE_URL}.json`, { admin_task: params });
};

export const fetchAdminTask = (params: IfetchAdminTask) => {
  return baseInstance.get(`${BASE_URL}/${params.id}.json`);
};

export const fetchAdminTasks = (params: IfetchAdminTasks) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};
