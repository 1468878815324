import { PlaceholderTile } from "./";

const PlaceholderTiles = () => {
  return (
    <div className="flex flex-wrap gap-3">
      {[0, 1, 2, 3, 4].map((key: number) => (
        <PlaceholderTile key={key} />
      ))}
    </div>
  );
};

export default PlaceholderTiles;
