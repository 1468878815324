import { useLayoutEffect, useRef, useState } from "react";

import { useBaseStore } from "../store";

import { IFaqContactObject } from "../interfaces/faqContactInterfaces";
import { fetchFaqContacts } from "../services/objects/faqContacts.service";
import { handleFetchObjectsResponse } from "../services/responseDataHandler";

const useClientTasks = (initPerPage: number) => {
  const refresh = useBaseStore((state) => state.refresh);

  const [objects, setObjects] = useState<IFaqContactObject[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(initPerPage);
  const [totalCount, setTotalCount] = useState<number>(0);
  const email = useRef<string>("");
  const statusScope = useRef<"" | "closed" | "pending" | "started">("");

  useLayoutEffect(() => {
    fetchObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, refresh]);

  const fetchObjects = () => {
    const params = { per_page: perPage, page: page, email: email.current, status: statusScope.current };
    fetchFaqContacts(params).then((response) => {
      handleFetchObjectsResponse({
        response: response,
        setTotalCount: setTotalCount,
        setObjects: setObjects
      });
    });
  };

  return { objects, setObjects, page, setPage, perPage, setPerPage, totalCount, setTotalCount, email, statusScope, fetchObjects };
};
export default useClientTasks;
