import { useCurrentOrdersStore } from "../../store";
import { ICarnetOrder, ICarnetOrderStore, IPrepaidCarnetToReserve } from "../../interfaces/storeInterfaces";

export const addPrepaidCarnetToReserveToCarnetOrder = (state: ICarnetOrderStore, prepaidCarnetToReserve: IPrepaidCarnetToReserve) => {
  const baseStore = useCurrentOrdersStore.getState();
  const carnetOrder = state.carnetOrders.find((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
  // Add new carnetOrder if there is no current carnetOrder
  if (baseStore.currentCarnetOrderId === -1 || carnetOrder === undefined) {
    const carnetOrderId = Date.now();
    useCurrentOrdersStore.setState((state) => {
      state.setCurrentCarnetOrderId(carnetOrderId);
      return { ...state, currentCarnetOrderId: carnetOrderId };
    });
    const carnetOrder: ICarnetOrder = {
      id: carnetOrderId,
      email: "",
      phone_number: "",
      prepaid_carnets_to_reserve: [prepaidCarnetToReserve]
    };
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    updatedCarnetOrders.push(carnetOrder);
    return { carnetOrders: updatedCarnetOrders };
  }

  // Adding prepaidCarnetToReserve to existing carnetOrder
  if (carnetOrder) {
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    const index = updatedCarnetOrders.findIndex((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
    updatedCarnetOrders[index].prepaid_carnets_to_reserve.push(prepaidCarnetToReserve);
    return { carnetOrders: updatedCarnetOrders };
  }

  const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
  return { carnetOrders: updatedCarnetOrders };
};

export const addPrepaidCarnetsToReserveToCarnetOrder = (state: ICarnetOrderStore, prepaidCarnetsToReserve: IPrepaidCarnetToReserve[]) => {
  const baseStore = useCurrentOrdersStore.getState();
  const carnetOrder = state.carnetOrders.find((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
  // Add new carnetOrder if there is no current carnetOrder
  if (baseStore.currentCarnetOrderId === -1 || carnetOrder === undefined) {
    const carnetOrderId = Date.now();
    useCurrentOrdersStore.setState((state) => {
      state.setCurrentCarnetOrderId(carnetOrderId);
      return { ...state, currentCarnetOrderId: carnetOrderId };
    });
    const carnetOrder: ICarnetOrder = {
      id: carnetOrderId,
      email: "",
      phone_number: "",
      prepaid_carnets_to_reserve: prepaidCarnetsToReserve
    };
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    updatedCarnetOrders.push(carnetOrder);
    return { carnetOrders: updatedCarnetOrders };
  }
  // Adding prepaidCarnetsToReserve to existing carnetOrder
  if (carnetOrder) {
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    const index = updatedCarnetOrders.findIndex((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
    updatedCarnetOrders[index].prepaid_carnets_to_reserve = prepaidCarnetsToReserve;
    return { carnetOrders: updatedCarnetOrders };
  }
  const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
  return { carnetOrders: updatedCarnetOrders };
};

export const changePrepaidCarnetToReserveCount = (state: ICarnetOrderStore, prepaidCarnetTemplateId: number, count: number) => {
  const baseStore = useCurrentOrdersStore.getState();
  const carnetOrder = state.carnetOrders.find((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
  if (carnetOrder && carnetOrder.prepaid_carnets_to_reserve.length) {
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    const index = updatedCarnetOrders.findIndex((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
    const prepaidCarnetsToReserve = updatedCarnetOrders[index].prepaid_carnets_to_reserve;
    const prepaidCarnetToReserveIndex = prepaidCarnetsToReserve.findIndex((prepaidCarnetToReserve: IPrepaidCarnetToReserve) => prepaidCarnetToReserve.id === prepaidCarnetTemplateId);

    if (prepaidCarnetToReserveIndex !== undefined && prepaidCarnetToReserveIndex !== -1) {
      updatedCarnetOrders[index].prepaid_carnets_to_reserve[prepaidCarnetToReserveIndex].count = count;
    }
    // remove prepaidCarnetToReserve if count is 0
    if (prepaidCarnetsToReserve[prepaidCarnetToReserveIndex].count === 0) {
      prepaidCarnetsToReserve.splice(prepaidCarnetToReserveIndex, 1);
    }
    // remove carnetOrder if there are no prepaidCarnetsToReserve, email and phone_number
    if (prepaidCarnetsToReserve.length === 0 && !updatedCarnetOrders[index].email && !updatedCarnetOrders[index].phone_number) {
      updatedCarnetOrders.splice(index, 1);
      useCurrentOrdersStore.setState((state) => {
        state.setCurrentCarnetOrderId(-1);
        return { ...state, currentCarnetOrderId: -1 };
      });
    }
    return { carnetOrders: updatedCarnetOrders };
  }
  return { carnetOrders: [] };
};

export const decreasePrepaidCarnetToReserveCount = (state: ICarnetOrderStore, prepaidCarnetTemplateId: number) => {
  const baseStore = useCurrentOrdersStore.getState();
  const carnetOrder = state.carnetOrders.find((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
  if (carnetOrder && carnetOrder.prepaid_carnets_to_reserve.length) {
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    const index = updatedCarnetOrders.findIndex((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
    const prepaidCarnetsToReserve = updatedCarnetOrders[index].prepaid_carnets_to_reserve;
    const prepaidCarnetToReserveIndex = prepaidCarnetsToReserve.findIndex((prepaidCarnetToReserve: IPrepaidCarnetToReserve) => prepaidCarnetToReserve.id === prepaidCarnetTemplateId);

    if (prepaidCarnetToReserveIndex !== undefined && prepaidCarnetToReserveIndex !== -1) {
      const isMinimumCount = prepaidCarnetsToReserve[prepaidCarnetToReserveIndex] && prepaidCarnetsToReserve[prepaidCarnetToReserveIndex].count <= 0;
      if (!isMinimumCount) prepaidCarnetsToReserve[prepaidCarnetToReserveIndex].count--;
      // remove prepaidCarnetToReserve if count is 0
      if (prepaidCarnetsToReserve[prepaidCarnetToReserveIndex].count === 0) {
        prepaidCarnetsToReserve.splice(prepaidCarnetToReserveIndex, 1);
      }
      // remove carnetOrder if there are no prepaidCarnetsToReserve, email and phone_number
      if (prepaidCarnetsToReserve.length === 0 && !updatedCarnetOrders[index].email && !updatedCarnetOrders[index].phone_number) {
        updatedCarnetOrders.splice(index, 1);
        useCurrentOrdersStore.setState((state) => {
          state.setCurrentCarnetOrderId(-1);
          return { ...state, currentCarnetOrderId: -1 };
        });
      }
    }
    return { carnetOrders: updatedCarnetOrders };
  }
  return { carnetOrders: [] };
};

export const increasePrepaidCarnetToReserveCount = (state: ICarnetOrderStore, prepaidCarnetTemplateId: number, maxCount: number) => {
  const baseStore = useCurrentOrdersStore.getState();
  const carnetOrder = state.carnetOrders.find((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
  if (carnetOrder && carnetOrder.prepaid_carnets_to_reserve.length) {
    const updatedCarnetOrders = JSON.parse(JSON.stringify(state.carnetOrders));
    const index = updatedCarnetOrders.findIndex((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
    const prepaidCarnetToReserveIndex = updatedCarnetOrders[index].prepaid_carnets_to_reserve.findIndex(
      (prepaidCarnetToReserve: IPrepaidCarnetToReserve) => prepaidCarnetToReserve.id === prepaidCarnetTemplateId
    );
    if (prepaidCarnetToReserveIndex !== undefined && prepaidCarnetToReserveIndex !== -1) {
      const prepaidCarnetToReserve = updatedCarnetOrders[index].prepaid_carnets_to_reserve[prepaidCarnetToReserveIndex];
      const isMaximumCount = prepaidCarnetToReserve && prepaidCarnetToReserve.count >= maxCount;
      if (!isMaximumCount) prepaidCarnetToReserve.count++;
    }
    return { carnetOrders: updatedCarnetOrders };
  }
  return { carnetOrders: [] };
};

export const removePrepaidCarnetToReserveFromCarnetOrder = (state: ICarnetOrderStore, prepaidCarnetTemplateId: number) => {
  const baseStore = useCurrentOrdersStore.getState();
  const carnetOrder = state.carnetOrders.find((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
  if (carnetOrder && carnetOrder.prepaid_carnets_to_reserve.length) {
    const updatedCarnetOrders = [...state.carnetOrders];
    const index = updatedCarnetOrders.findIndex((carnetOrder: ICarnetOrder) => carnetOrder.id === baseStore.currentCarnetOrderId);
    const prepaidCarnetToReserveIndex = updatedCarnetOrders[index].prepaid_carnets_to_reserve.findIndex(
      (prepaidCarnetToReserve: IPrepaidCarnetToReserve) => prepaidCarnetToReserve.id === prepaidCarnetTemplateId
    );
    if (prepaidCarnetToReserveIndex !== undefined && prepaidCarnetToReserveIndex !== -1) {
      updatedCarnetOrders[index].prepaid_carnets_to_reserve.splice(prepaidCarnetToReserveIndex, 1);
      // remove carnetOrder if there are no prepaid_carnets_to_reserve, email and phone_number
      if (updatedCarnetOrders[index].prepaid_carnets_to_reserve.length === 0 && !updatedCarnetOrders[index].email && !updatedCarnetOrders[index].phone_number) {
        updatedCarnetOrders.splice(index, 1);
        useCurrentOrdersStore.setState((state) => {
          state.setCurrentCarnetOrderId(-1);
          return { ...state, currentCarnetOrderId: -1 };
        });
      }
    }
    return { carnetOrders: updatedCarnetOrders };
  }
  return { carnetOrders: [] };
};
