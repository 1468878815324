import { useLayoutEffect, useState } from "react";

import { useBaseStore } from "../store";

import { fetchCarnetBase, fetchCarnetBaseForPrint } from "../services/objects/carnetBases.service";
import { handleFetchObjectResponse } from "../services/responseDataHandler";
import { ICarnetBaseObject } from "../interfaces/carnetBaseInterfaces";

const useCarnetBase = (id?: number, forPrint?: boolean) => {
  const refresh = useBaseStore((state) => state.refresh);
  const setGlobalLoader = useBaseStore((state) => state.setGlobalLoader);
  const [carnetBase, setCarnetBase] = useState<ICarnetBaseObject | undefined>(undefined);

  const fetchObject = () => {
    if (!id || id === -1) return;

    if (forPrint) {
      fetchCarnetBaseForPrint({ id: id }).then((res) => {
        handleFetchObjectResponse({ response: res, setObject: setCarnetBase, setGlobalLoader: setGlobalLoader });
      });
    } else {
      fetchCarnetBase({ id: id }).then((res) => {
        handleFetchObjectResponse({ response: res, setObject: setCarnetBase, setGlobalLoader: setGlobalLoader });
      });
    }
  };

  useLayoutEffect(() => {
    fetchObject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refresh]);

  return { carnetBase, setCarnetBase };
};
export default useCarnetBase;
