import { BiSolidUpArrow } from "react-icons/bi";

import { useClickAway } from "../../hooks";

const Popover = (props: any) => {
  const { children, open, setOpen, buttonRef } = props;

  const popoverRef = useClickAway(() => setOpen(false));

  if (!open || !buttonRef) return null;

  const rect = buttonRef.current.getBoundingClientRect();

  return (
    <div className="transition duration-150 ease-in-out -mt-20 absolute ml-20 w-72" style={{ top: rect.top + 130, left: rect.left - 288 * 0.75 }}>
      <div className="w-full bg-white rounded shadow-2xl">
        <div className="relative bg-gray-200 rounded-t">
          <div className="absolute top-0 -mt-6 left-1/2 -translate-x-1/2">
            <BiSolidUpArrow size={30} color="#fff" />
          </div>
        </div>
        <div className="overflow-auto max-h-96" ref={popoverRef}>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Popover;
