import { useLayoutEffect, useState } from "react";

import { useBaseStore } from "../store";

import { fetchEvent } from "../services/objects/events.service";
import { handleFetchObjectResponse } from "../services/responseDataHandler";
import { IEventObject } from "../interfaces/eventInterfaces";

const useEvent = (id?: string | undefined | number, propsEvent?: IEventObject) => {
  const refresh = useBaseStore((state) => state.refresh);

  const [event, setEvent] = useState<IEventObject | undefined>(propsEvent);
  useLayoutEffect(() => {
    if (!id || propsEvent?.id === id) return;

    fetchEvent({ id: +id, all_sold: true }).then((response) => {
      handleFetchObjectResponse({ response: response, setObject: setEvent });
    });
  }, [id, propsEvent, refresh]);

  return { event, setEvent };
};
export default useEvent;
