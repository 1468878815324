import { useLayoutEffect, useRef, useState } from "react";

import { useBaseStore } from "../store";
import { useCooperator } from "../hooks";

import { fetchProducts } from "../services/objects/products.service";
import { handleFetchObjectsResponse } from "../services/responseDataHandler";

import { IProductObject, IfetchProducts } from "../interfaces/productInterfaces";

const useProducts = (perPageInit: number) => {
  const refresh = useBaseStore((state) => state.refresh);
  const cooperatorId = useCooperator()[0];

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(perPageInit);
  const ean = useRef<string>("");
  const name = useRef<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [objects, setObjects] = useState<IProductObject[] | null>(null);

  useLayoutEffect(() => {
    const params = prepareRequestParams(page, name.current, ean.current);
    fetchObjects(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page, refresh]);

  const fetchObjects = (params: IfetchProducts) => {
    fetchProducts(params).then((response) => {
      handleFetchObjectsResponse({
        response: response,
        setTotalCount: setTotalCount,
        setObjects: setObjects
      });
    });
  };

  const prepareRequestParams = (page: number, name: string, ean: string) => ({
    cooperator_id: cooperatorId,
    per_page: perPage,
    page: page,
    name: name,
    ean: ean
  });

  return { page, setPage, perPage, setPerPage, ean, name, totalCount, setTotalCount, objects, setObjects, fetchObjects, prepareRequestParams };
};
export default useProducts;
