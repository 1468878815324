import { useCurrentOrdersStore, useCarnetOrdersStore } from "../store";

import { clampToRange } from "../services/commons";

import { IPrepaidCarnetToReserve, IProduct } from "../interfaces/storeInterfaces";
import { IPrepaidCarnetTemplateObject } from "../interfaces/prepaidCarnetTemplateInterfaces";

const usePrepaidCarnetsToReserve = (
  object: IPrepaidCarnetTemplateObject,
  maxCount: number,
  prepaidCarnetToReserve?: IPrepaidCarnetToReserve | null,
  setProducts?: React.Dispatch<React.SetStateAction<IProduct[]>>
) => {
  const currentCarnetOrderId = useCurrentOrdersStore((state) => state.currentCarnetOrderId);
  const carnetOrder = useCarnetOrdersStore((state) => state.fetchCarnetOrder(currentCarnetOrderId));
  const addPrepaidCarnetToReserveToCarnetOrder = useCarnetOrdersStore((state) => state.addPrepaidCarnetToReserveToCarnetOrder);
  const increasePrepaidCarnetToReserveCount = useCarnetOrdersStore((state) => state.increasePrepaidCarnetToReserveCount);
  const decreasePrepaidCarnetToReserveCount = useCarnetOrdersStore((state) => state.decreasePrepaidCarnetToReserveCount);
  const changePrepaidCarnetToReserveCount = useCarnetOrdersStore((state) => state.changePrepaidCarnetToReserveCount);

  const handleAddPrepaidCarnetToReserve = () => {
    // if there is already an order and this product is in it, increase quantity
    if (carnetOrder && carnetOrder.prepaid_carnets_to_reserve && carnetOrder.prepaid_carnets_to_reserve.find((prepaidCarnetToReserve) => prepaidCarnetToReserve.id === object.id)) {
      increasePrepaidCarnetToReserveCount(object.id, maxCount);
    } else {
      const newPrepaidCarnetToReserve: IPrepaidCarnetToReserve = newPrepaidCarnetToReserveObject(1);
      addPrepaidCarnetToReserveToCarnetOrder(newPrepaidCarnetToReserve);
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const newCount = clampToRange(value, 0, maxCount);

    if (carnetOrder && carnetOrder.prepaid_carnets_to_reserve && carnetOrder.prepaid_carnets_to_reserve.find((prepaidCarnetToReserve) => prepaidCarnetToReserve.id === object.id)) {
      changePrepaidCarnetToReserveCount(object.id, newCount);
    } else {
      if (newCount === 0) return;
      const newPrepaidCarnetToReserve: IPrepaidCarnetToReserve = newPrepaidCarnetToReserveObject(newCount);
      addPrepaidCarnetToReserveToCarnetOrder(newPrepaidCarnetToReserve);
    }
  };

  const handleRemovePrepaidCarnetToReserve = () => {
    if (prepaidCarnetToReserve && prepaidCarnetToReserve.count > 0) {
      decreasePrepaidCarnetToReserveCount(object.id);
    }
  };

  const newPrepaidCarnetToReserveObject = (count: number) => {
    return {
      id: object.id,
      count: count,
      name: object.name,
      price: parseFloat(String(object.price_gross)),
      image: object.picture_url,
      maxCount: maxCount
    };
  };

  return { handleAddPrepaidCarnetToReserve, handleOnBlur, handleRemovePrepaidCarnetToReserve };
};
export default usePrepaidCarnetsToReserve;
