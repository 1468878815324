import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";

import { MdFirstPage, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdLastPage } from "react-icons/md";

import { Button, Input, Select } from "./";

import { useCashierSettings } from "../../hooks";

import { IPagination } from "../../interfaces/sharedComponentInterfaces";

const Pagination = (props: IPagination) => {
  const { page, setPage, perPage, setPerPage, perPageOptions, settings, settingType, settingKey = "perPage", size, totalCount } = props;

  const { changeCurrentCashierSetting } = useCashierSettings();

  const [newPage, setNewPage] = useState<number>(page);

  const totalPages = Math.ceil(totalCount / perPage);
  const selectOptions = perPageOptions || [5, 10, 20, 30, 40, 50];

  const selectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newPerPage = +event.target.value;
    setPerPage(() => newPerPage);
  };

  useEffect(() => {
    if (settings && settingType) {
      changeCurrentCashierSetting(settingType, { ...settings, [settingKey]: perPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  const selectPage = (pageNumber: number) => {
    setPage(pageNumber);
    setNewPage(pageNumber);
  };

  const handleEnterKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const inputValue = +event.currentTarget.value;
      if (inputValue < 1) {
        setPage(1);
      } else if (inputValue > totalPages) {
        setPage(totalPages);
      } else {
        setPage(inputValue);
      }
    }
  };

  useEffect(() => {
    setNewPage(page);
  }, [page]);

  if (totalCount <= 0) return null;

  let baseClasses = "pb-3 flex justify-center items-center gap-2 font-medium";
  if (true) {
    baseClasses += " sticky bottom-0";
  }

  let buttonStyles = "h-10 w-10 text-2xl";
  let inputStyles = "h-10 w-20";
  let selectStyles = "h-10";
  if (size === "small") {
    buttonStyles = "h-8 w-8 text-xl";
    inputStyles = "h-8 w-16 text-sm";
    selectStyles = "h-8 text-sm px-2 py-0";
  }

  return (
    <div className={baseClasses} data-testid="pagination">
      <div>
        <Select defaultValue={perPage} onChange={selectChange} customClasses={selectStyles} testId="select-per-page">
          {selectOptions.map((item: number) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
      </div>
      {totalPages > 0 ? (
        <div className="flex justify-center items-center gap-2" data-testid="page-wrapper">
          <div className="flex justify-center items-center gap-2" data-testid="pagination-left">
            <Button buttonStyle="white" onClick={() => selectPage(1)} customClasses={buttonStyles} testId="page-first" disabled={page === 1}>
              <MdFirstPage />
            </Button>
            <Button buttonStyle="white" onClick={() => selectPage(page - 1)} customClasses={buttonStyles} testId="prev-page" disabled={page === 1}>
              <MdKeyboardArrowLeft />
            </Button>
          </div>
          <Input type="number" onChange={(e) => setNewPage(+e.target.value)} onKeyDown={handleEnterKeyPress} max={totalPages} min={1} value={newPage} testId="page-input" customClasses={inputStyles} />
          <div className="flex justify-center items-center gap-2" data-testid="pagination-right">
            <Button buttonStyle="white" onClick={() => selectPage(page + 1)} customClasses={buttonStyles} testId="next-page" disabled={page === totalPages}>
              <MdKeyboardArrowRight />
            </Button>
            <Button buttonStyle="white" onClick={() => selectPage(totalPages)} customClasses={buttonStyles} testId="page-last" disabled={page === totalPages}>
              <MdLastPage />
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Pagination;
