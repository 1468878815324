import { ITextArea } from "../../interfaces/sharedComponentInterfaces";

const TextArea = (props: ITextArea) => {
  const { autoFocus, customClasses, errors, hideErrors, label, labelFs, onChange, onKeyDown, placeholder, register, testId } = props;

  const baseClasses = "block p-2 focus:outline-none border w-full";

  let errorClasses = "text-gray-900 placeholder-zinc-800 border-gray-300 bg-gray-50 focus:border-emerald-500";
  if (errors) {
    errorClasses = "text-red-900 placeholder-red-700 border-red-500 bg-red-50 focus:border-red-500";
  }

  return (
    <div className="w-full">
      {label && <label className={`${labelFs ? labelFs : "text-xs"} text-left`}>{label}:</label>}
      <textarea
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className={`${baseClasses} ${errorClasses} ${customClasses}`}
        autoFocus={autoFocus}
        data-testid={testId}
        {...register}
      />
      {!hideErrors && errors && <p className="text-xs text-red-500">{errors.message ? String(errors.message) : ""}</p>}
    </div>
  );
};

export default TextArea;
