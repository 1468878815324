import { IButton } from "../../interfaces/sharedComponentInterfaces";

const Button = (props: IButton) => {
  const { buttonStyle = "success", children, customClasses, disabled, onClick, testId, type, tooltipId, tooltipText } = props;

  let bgColors = "bg-emerald-500 hover:bg-emerald-600 disabled:bg-emerald-400";
  let textColor = "text-slate-100";
  switch (buttonStyle) {
    case "error":
      bgColors = "bg-red-500 hover:bg-red-600 disabled:bg-red-300";
      break;
    case "primary":
      bgColors = "bg-sky-400 hover:bg-sky-500 disabled:bg-sky-300";
      textColor = "text-slate-800";
      break;
    case "warning":
      bgColors = "bg-yellow-400 hover:bg-yellow-500 disabled:bg-yellow-300";
      textColor = "text-slate-800";
      break;
    case "white":
      bgColors = "bg-slate-50 hover:bg-slate-100 disabled:bg-slate-200 border border-gray-300";
      textColor = "text-slate-800 disabled:text-slate-400";
      break;
    case "dark_primary":
      bgColors = "bg-blue-500 hover:bg-sky-600 disabled:bg-sky-400";
      textColor = "text-zinc-100";
  }
  const basicClasses = `p-2 flex items-center justify-center ${textColor} ${bgColors} disabled:cursor-default transition-colors`;

  return (
    <button
      className={`${basicClasses} ${customClasses}`}
      type={type || "button"}
      onClick={onClick}
      data-testid={testId}
      disabled={disabled}
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltipText}>
      {children}
    </button>
  );
};

export default Button;
