export const fetchQueryParams = (params: any = null, ids: string[] = [""]) => {
  let queryParams = "";
  if (params) {
    for (let nr in Object.keys(params)) {
      if (nr === "0") {
        queryParams = `${queryParams}?`;
      } else if (!["?", "&"].includes(queryParams.slice(-1))) {
        queryParams = `${queryParams}&`;
      }
      const key: string = Object.keys(params)[nr];
      const value: any = Object.values(params)[nr];
      // TODO: handle array
      if (value === "" || (ids.includes(key) && (value === -1 || value === 0 || isNaN(value)))) {
        continue;
      }
      queryParams = `${queryParams}${key}=${value}`;
    }
  }
  return queryParams;
};
