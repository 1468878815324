import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { NotificationDot } from "../../shared/components";

import { useCarnetBasePrintingProblems, useOrderPrintingProblems, useProductOrderPrintingProblems, useCarnetOrderPrintingProblems } from "../../hooks";

import { CARNET_ORDERS, CARNETS, ORDERS, PRODUCT_ORDERS } from "../../shared/routes";

export const OrderTabs = () => {
  const { countSkippedProductOrders } = useProductOrderPrintingProblems();
  const { countSkippedOrders } = useOrderPrintingProblems();
  const { countSkippedCarnetBases } = useCarnetBasePrintingProblems();
  const { countSkippedCarnetOrders } = useCarnetOrderPrintingProblems();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const skippedOrders = countSkippedOrders();
  const skippedProductOrders = countSkippedProductOrders();
  const skippedCarnetBases = countSkippedCarnetBases();
  const skippedCarnetOrders = countSkippedCarnetOrders();

  const handleCarnetBasesClick = () => {
    if (pathname === CARNETS) return;

    navigate(CARNETS);
  };

  const handleCarnetOrdersClick = () => {
    if (pathname === CARNET_ORDERS) return;

    navigate(CARNET_ORDERS);
  };

  const handleOrdersClick = () => {
    if (pathname === ORDERS) return;

    navigate(ORDERS);
  };

  const handleProductOrdersClick = () => {
    if (pathname === PRODUCT_ORDERS) return;

    navigate(PRODUCT_ORDERS);
  };

  return (
    <div>
      <button className={`px-4 py-2 relative ${pathname === ORDERS ? "bg-zinc-100 font-semibold" : "bg-zinc-200"}`} onClick={handleOrdersClick}>
        {t("order.tabs.orders")} <NotificationDot quantity={skippedOrders} />
      </button>
      <button className={`px-4 py-2 relative ${pathname === PRODUCT_ORDERS ? "bg-zinc-100 font-semibold" : "bg-zinc-200"}`} onClick={handleProductOrdersClick}>
        {t("order.tabs.product_orders")} <NotificationDot quantity={skippedProductOrders} />
      </button>
      <button className={`px-4 py-2 relative ${pathname === CARNETS ? "bg-zinc-100 font-semibold" : "bg-zinc-200"}`} onClick={handleCarnetBasesClick}>
        {t("order.tabs.carnet_bases")}
        <NotificationDot quantity={skippedCarnetBases} />
      </button>
      <button className={`px-4 py-2 relative ${pathname === CARNET_ORDERS ? "bg-zinc-100 font-semibold" : "bg-zinc-200"}`} onClick={handleCarnetOrdersClick}>
        {t("order.tabs.carnet_orders")}
        <NotificationDot quantity={skippedCarnetOrders} />
      </button>
    </div>
  );
};

export default OrderTabs;
