import { useCurrentOrdersStore } from "../../store";
import { IOrder, IOrderStore, ISeat, IselectedSeat } from "../../interfaces/storeInterfaces";

export const addSelectedSeatToOrder = (state: IOrderStore, selectedSeat: IselectedSeat, eventId: number, eventTitle: string, hallId?: number, hallWrapperElementId?: number, seat?: ISeat) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  // Add new order if there is no current order
  if (currentOrdersStore.currentOrderId === -1 || order === undefined) {
    return createNewOrder(state, selectedSeat, eventId, eventTitle, hallId, hallWrapperElementId, seat);
  }

  if (order && order.hallId && order.hallId === hallId) {
    // Creating new order if current order is for different event
    if (order.eventId && order.eventId !== eventId) {
      return createNewOrder(state, selectedSeat, eventId, eventTitle, hallId, hallWrapperElementId, seat);
    }
    // Adding selectedSeat to existing order
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    updatedOrders[index].eventId = eventId;
    updatedOrders[index].eventTitle = eventTitle;
    updatedOrders[index].hallId = hallId;
    updatedOrders[index].hallWrapperElementId = hallWrapperElementId;
    updatedOrders[index].selectedSeats.push(selectedSeat);
    if (seat) {
      updatedOrders[index].seats.push(seat);
    }
    return { orders: updatedOrders };
  } else {
    return createNewOrder(state, selectedSeat, eventId, eventTitle, hallId, hallWrapperElementId, seat);
  }
};

export const addSelectedSeatsToOrder = (state: IOrderStore, selectedSeats: IselectedSeat[], eventId: number, eventTitle: string, hallId?: number, hallWrapperElementId?: number, seats?: ISeat[]) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  // Add new order if there is no current order
  if (currentOrdersStore.currentOrderId === -1 || order === undefined) {
    return createNewOrderWithSeats(state, selectedSeats, eventId, eventTitle, hallId, hallWrapperElementId, seats);
  }
  if (order) {
    // Creating new order if current order is for different event
    if (order.eventId && order.eventId !== eventId) {
      return createNewOrderWithSeats(state, selectedSeats, eventId, eventTitle, hallId, hallWrapperElementId, seats);
    }
    // Adding selectedSeat to existing order
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    updatedOrders[index].eventId = eventId;
    updatedOrders[index].eventTitle = eventTitle;
    updatedOrders[index].hallId = hallId;
    updatedOrders[index].hallWrapperElementId = hallWrapperElementId;
    updatedOrders[index].selectedSeats = selectedSeats;
    if (seats) {
      updatedOrders[index].seats = seats;
    }
    return { orders: updatedOrders };
  }
  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  return { orders: updatedOrders };
};

export const removeSelectedSeatFromOrder = (state: IOrderStore, selectedSeatId: number) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.selectedSeats.length) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const selectedSeatIndex = updatedOrders[index].selectedSeats.findIndex((selectedSeat: IselectedSeat) => selectedSeat.viewId === selectedSeatId);
    if (selectedSeatIndex !== undefined && selectedSeatIndex !== -1) {
      const sectorId = updatedOrders[index].selectedSeats[selectedSeatIndex].sectorId;
      const viewId = updatedOrders[index].selectedSeats[selectedSeatIndex].viewId;
      if (sectorId) {
        const seatIndex = updatedOrders[index].seats.findIndex((seat: ISeat) => seat.viewId === viewId && seat.sectorId === sectorId);
        if (seatIndex !== undefined && seatIndex !== -1) {
          updatedOrders[index].seats.splice(seatIndex, 1);
        }
      }
      updatedOrders[index].selectedSeats.splice(selectedSeatIndex, 1);

      if (updatedOrders[index].selectedSeats.length === 0) {
        updatedOrders[index].eventId = undefined;
        updatedOrders[index].eventTitle = undefined;
        updatedOrders[index].hallId = undefined;
        updatedOrders[index].hallWrapperElementId = undefined;
      }

      // remove order if there are no seats / sectors / products / activities / voucher
      if (
        updatedOrders[index].selectedSeats.length === 0 &&
        updatedOrders[index].sectors.length === 0 &&
        updatedOrders[index].products.length === 0 &&
        updatedOrders[index].activities.length === 0 &&
        updatedOrders[index].voucher === undefined
      ) {
        updatedOrders.splice(index, 1);
        useCurrentOrdersStore.setState((state) => {
          state.removeCurrentOrderId();
          return { ...state, currentOrderId: -1 };
        });
      }
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

export const removeSelectedSeatsFromOrder = (state: IOrderStore) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (updatedOrders[index]) {
    updatedOrders[index].hallId = undefined;
    updatedOrders[index].hallWrapperElementId = undefined;
    updatedOrders[index].seats = [];
    updatedOrders[index].selectedSeats = [];
    if (updatedOrders[index].products.length === 0) {
      updatedOrders.splice(index, 1);
      useCurrentOrdersStore.setState((state) => {
        state.removeCurrentOrderId();
        return { ...state, currentOrderId: -1 };
      });
    }
  }
  return { orders: updatedOrders };
};

export const updateSelectedSeatWithSeat = (state: IOrderStore, selectedSeatId: number, seat?: ISeat) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.selectedSeats.length) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const selectedSeatIndex = updatedOrders[index].selectedSeats.findIndex((selectedSeat: IselectedSeat) => selectedSeat.viewId === selectedSeatId);
    if (selectedSeatIndex !== undefined && selectedSeatIndex !== -1) {
      const selectedSeat = updatedOrders[index].selectedSeats[selectedSeatIndex];
      // TODO: check if search by viewId or id sectorId
      const seatIndex = updatedOrders[index].seats.findIndex((seat: ISeat) => seat.viewId === selectedSeat.viewId);
      if (seatIndex !== undefined && seatIndex !== -1) {
        selectedSeat.sectorId = undefined;
        updatedOrders[index].seats.splice(seatIndex, 1);
      }
      if (seat) {
        selectedSeat.sectorId = seat.sectorId;
        updatedOrders[index].seats.push(seat);
      }
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

const createNewOrder = (state: IOrderStore, selectedSeat: IselectedSeat, eventId: number, eventTitle: string, hallId?: number, hallWrapperElementId?: number, seat?: ISeat) => {
  const orderId = Date.now();
  useCurrentOrdersStore.setState((state) => {
    state.setCurrentOrderId(orderId);
    return { ...state, currentOrderId: orderId };
  });
  const order: IOrder = {
    id: orderId,
    eventId: eventId,
    eventTitle: eventTitle,
    hallId: hallId,
    hallWrapperElementId: hallWrapperElementId,
    activities: [],
    products: [],
    reservationId: undefined,
    reservationSeats: undefined,
    sectors: [],
    seats: seat !== undefined ? [seat] : [],
    selectedSeats: [selectedSeat],
    voucher: undefined
  };
  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  updatedOrders.push(order);
  return { orders: updatedOrders };
};

const createNewOrderWithSeats = (state: IOrderStore, selectedSeats: IselectedSeat[], eventId: number, eventTitle: string, hallId?: number, hallWrapperElementId?: number, seats?: ISeat[]) => {
  const orderId = Date.now();
  useCurrentOrdersStore.setState((state) => {
    state.setCurrentOrderId(orderId);
    return { ...state, currentOrderId: orderId };
  });
  const order: IOrder = {
    id: orderId,
    eventId: eventId,
    eventTitle: eventTitle,
    hallId: hallId,
    hallWrapperElementId: hallWrapperElementId,
    activities: [],
    products: [],
    reservationId: undefined,
    reservationSeats: undefined,
    sectors: [],
    seats: seats !== undefined ? seats : [],
    selectedSeats: selectedSeats,
    voucher: undefined
  };
  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  updatedOrders.push(order);
  return { orders: updatedOrders };
};
