import { useLayoutEffect, useState } from "react";

import { useBaseStore } from "../store";

import { fetchProductOrder } from "../services/objects/productOrders.service";

import { IProductOrderObject, IfetchProductOrder } from "../interfaces/productOrderInterfaces";
import { handleFetchObjectResponse } from "../services/responseDataHandler";

const useProductOrder = (productOrderId: number | undefined, id?: number) => {
  const refresh = useBaseStore((state) => state.refresh);
  const setGlobalLoader = useBaseStore((state) => state.setGlobalLoader);

  const [productOrder, setProductOrder] = useState<IProductOrderObject | undefined>(undefined);

  useLayoutEffect(() => {
    if (!productOrderId || productOrderId === -1) return;
    const productParams: IfetchProductOrder = {
      id: productOrderId,
      order_id: id
    };
    fetchProductOrder(productParams).then((response) => {
      handleFetchObjectResponse({ response: response, setObject: setProductOrder, setGlobalLoader: setGlobalLoader });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOrderId, refresh]);

  return { productOrder, setProductOrder };
};
export default useProductOrder;
