import { RefObject, useLayoutEffect, useMemo, useState } from "react";

import { fetchEkoMessages } from "../services/objects/ekoMessages.service";

import { IEkoMessageObject, IfetchEkoMessages } from "../interfaces/ekoMessageInterfaces";

const useEkoMessages = (taskId: number | null, type: "FaqContact" | "AdminTask", ref?: RefObject<HTMLDivElement> | null) => {
  const [objects, setObjects] = useState<IEkoMessageObject[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const perPage = 10;

  const isOnMaxPage = useMemo(() => totalCount / 10 <= page, [page, totalCount]);

  useLayoutEffect(() => {
    if (!taskId) return;
    const ekoParams: IfetchEkoMessages = {
      taskable_type: type,
      taskable_id: taskId,
      page: page,
      per_page: perPage
    };
    fetchEkoMessages(ekoParams).then((response) => {
      if (response && response.status === 200) {
        setTotalCount(response.data.items_count);
        setObjects(response.data.items);
        setPage(1);
        if (ref?.current) {
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      } else {
        console.log(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, refresh]);

  useLayoutEffect(() => {
    if (!taskId || !ref || !objects || page === 1) return;
    setIsLoading(true);
    const ekoParams: IfetchEkoMessages = {
      taskable_type: type,
      taskable_id: taskId,
      page: page,
      per_page: perPage
    };
    fetchEkoMessages(ekoParams).then((response) => {
      if (response && response.status === 200) {
        if (ref?.current) {
          const scrollTop = ref.current.scrollTop ?? 0;
          const scrollHeight = ref.current.scrollHeight ?? 0;
          const clientHeight = ref.current.clientHeight ?? 0;
          setObjects((prev) => [...response.data.items, ...(prev || [])]);
          ref.current.scrollTop = scrollTop + ref.current.scrollHeight - scrollHeight + clientHeight;
        }
      } else {
        console.log(response);
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return { objects, setObjects, page, setPage, totalCount, setTotalCount, refresh, setRefresh, isLoading, isOnMaxPage };
};
export default useEkoMessages;
