import { lazy, Suspense } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import { Loader } from "../../shared/components";

import * as routes from "../../shared/routes";

const Login = lazy(() => import("../Auth/Login"));
const SecondDisplay = lazy(() => import("../SecondDisplay/SecondDisplay"));

const BasicRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Main routes */}
        <Route path={routes.LOGIN} element={<Login />} />

        {/* Second display routes */}
        <Route path={routes.SECOND_DISPLAY} element={<SecondDisplay />} />

        {/* Fallback routes */}
        <Route path="*" element={<Navigate to={routes.LOGIN} />} />
      </Routes>
    </Suspense>
  );
};

export default BasicRoutes;
