import { Tile } from "./";

const PlaceholderTile = () => {
  return (
    <Tile pulse={true}>
      <div className="rounded-md bg-zinc-300 mt-2 w-11/12 h-6"></div>
      <div className="rounded-md bg-zinc-300 mt-2 w-11/12 h-40"></div>
      <div className="flex justify-between items-center h-16 w-11/12">
        <div className="rounded-md bg-zinc-300 h-10 w-full"></div>
      </div>
    </Tile>
  );
};

export default PlaceholderTile;
