import {
  IfiscalInvoicePrint,
  IfiscalNonFiscalInvoicePrint,
  IfiscalNonFiscalPrint,
  IfiscalPrint,
  IfiscalPrinter,
  IfiscalPrinterSettings,
  InonFiscalPrint,
  IticketPrinter
} from "../interfaces/printerInterfaces";

// Workaround for window.api property not being recognized by Typescript
declare global {
  interface Window {
    api: any;
  }
}

const checkPrinterConnection = async (printerSettings: IfiscalPrinterSettings) => {
  const printerList = await listFiscalPrinters();
  if (!printerList) return false;

  return printerList.ports.some((printer: IfiscalPrinter) => printer.portObject.path === printerSettings.port.name) ? true : false;
};

export const fiscalInvoicePrint = async (props: IfiscalInvoicePrint) => {
  const { printData, printerSettings } = props;
  if (!(await checkPrinterConnection(printerSettings))) return false;

  try {
    await window.api.fiscalPrinterActions("fiscalPrinterInvoicePrint", { printData: printData, printerSetting: printerSettings });
    return true;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const fiscalNonFiscalPrint = async (props: IfiscalNonFiscalPrint) => {
  const { customLines, printerSettings } = props;
  if (!(await checkPrinterConnection(printerSettings))) return false;

  try {
    await window.api.fiscalPrinterActions("fiscalPrinterPrintNonFiscalReceipt", { printData: { custom_lines: customLines }, printerSetting: printerSettings });
    return true;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const fiscalNonFiscalInvoicePrint = async (props: IfiscalNonFiscalInvoicePrint) => {
  const { customLines, printerSettings } = props;
  if (!(await checkPrinterConnection(printerSettings))) return false;

  try {
    await window.api.fiscalPrinterActions("fiscalPrinterPrintNonFiscalReceipt", { printData: { custom_lines: customLines }, printerSetting: printerSettings });
    return true;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const fiscalPrint = async (props: IfiscalPrint) => {
  const { printData, printerSettings } = props;
  if (!(await checkPrinterConnection(printerSettings))) return false;

  try {
    await window.api.fiscalPrinterActions("fiscalPrinterPrintReceipt", { printData: printData, printerSetting: printerSettings });
    return true;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const listFiscalPrinters = async () => {
  if (!window.api?.fiscalPrinterActions) return false;

  try {
    const response = await window.api.fiscalPrinterActions("listFiscalPrinters");
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const listNonFiscalPrinters = async () => {
  if (!window.api?.ticketPrinterActions) return false;

  try {
    const response = await window.api.ticketPrinterActions("listTicketPrinters");
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};

export const nonFiscalPrint = async (props: InonFiscalPrint) => {
  const printerList = await listNonFiscalPrinters();
  if (!printerList) return false;

  const { carnetPrint, printData, printerModel, printerName, logotypes } = props;
  if (!printerList.printer_list.some((printerInfo: IticketPrinter) => printerInfo.name === printerName)) return false;
  if (!printerList.printer_model_list.some((model: string) => model === printerModel)) return false;

  const actionName = `${carnetPrint ? "carnet" : "ticket"}PrinterPrint`;
  try {
    await window.api.ticketPrinterActions(actionName, { print_data: printData, printer_model: printerModel, printer_name: printerName, logotypes: logotypes });
    return true;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
};
