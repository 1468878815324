import { useCurrentOrdersStore } from "../../store";
import { IOrder, IOrderStore } from "../../interfaces/storeInterfaces";
import { IVoucherObject } from "../../interfaces/voucherInterfaces";

export const addVoucherToOrder = (state: IOrderStore, voucher: IVoucherObject) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  // Add new order if there is no current order
  if (currentOrdersStore.currentOrderId === -1 || order === undefined) {
    const orderId = Date.now();
    useCurrentOrdersStore.setState((state) => {
      state.setCurrentOrderId(orderId);
      return { ...state, currentOrderId: orderId };
    });
    const order: IOrder = {
      id: orderId,
      products: [],
      reservationId: undefined,
      reservationSeats: undefined,
      activities: [],
      sectors: [],
      seats: [],
      selectedSeats: [],
      voucher: voucher
    };
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    updatedOrders.push(order);
    return { orders: updatedOrders };
  }

  // Assigning voucher to existing order
  if (order) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    updatedOrders[index].voucher = voucher;
    return { orders: updatedOrders };
  }

  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  return { orders: updatedOrders };
};

export const removeVoucherFromOrder = (state: IOrderStore) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    updatedOrders[index].voucher = undefined;
    // remove order if there are no products / sectors / seats / activities / voucher
    if (
      updatedOrders[index].products.length === 0 &&
      updatedOrders[index].sectors.length === 0 &&
      updatedOrders[index].selectedSeats.length === 0 &&
      updatedOrders[index].activities.length === 0 &&
      updatedOrders[index].voucher === undefined
    ) {
      updatedOrders.splice(index, 1);
      useCurrentOrdersStore.setState((state) => {
        state.removeCurrentOrderId();
        return { ...state, currentOrderId: -1 };
      });
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};
