import { baseInstance } from "../../axiosCashier";
import {
  IcomplainCarnetBase,
  IconfirmCarnetBase,
  IcreateCarnetBase,
  IdestroyCarnetBase,
  IfetchCarnetBase,
  IfetchCarnetBaseForPrint,
  IfetchCarnetBases,
  IfetchCarnetBasesForPosNewInvoice,
  IfindForOrderCarnetBase,
  IprintCarnetBase,
  IprintCarnetBaseMultiple,
  IupdateCarnetBase
} from "../../interfaces/carnetBaseInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/carnet_bases";

export const complainCarnetBase = (params: IcomplainCarnetBase) => {
  const complainParams = fetchQueryParams(params, ["id"]);
  return baseInstance.patch(`${BASE_URL}/${params.id}/complain.json`, complainParams);
};

export const confirmCarnetBase = (params: IconfirmCarnetBase) => {
  const confirmParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/confirm.json`, confirmParams);
};

export const createCarnetBase = (params: IcreateCarnetBase) => {
  return baseInstance.post(`${BASE_URL}.json`, params);
};

export const destroyCarnetBase = (params: IdestroyCarnetBase) => {
  return baseInstance.delete(`${BASE_URL}/${params.id}.json`);
};

export const fetchCarnetBase = (params: IfetchCarnetBase) => {
  return baseInstance.get(`${BASE_URL}/${params.id}.json`);
};

export const fetchCarnetBaseForPrint = (params: IfetchCarnetBaseForPrint) => {
  return baseInstance.get(`${BASE_URL}/${params.id}/show_for_print.json`);
};

export const fetchCarnetBases = (params: IfetchCarnetBases) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};

export const fetchCarnetBasesForPosNewInvoice = (params: IfetchCarnetBasesForPosNewInvoice) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}/index_for_pos_new_invoice.json${queryParams}`);
};

export const findForOrderCarnetBase = (params: IfindForOrderCarnetBase) => {
  const queryParams = fetchQueryParams(params, ["order_id"]);
  return baseInstance.get(`${BASE_URL}/find_for_order.json${queryParams}`);
};

export const printCarnetBase = (params: IprintCarnetBase) => {
  return baseInstance.patch(`${BASE_URL}/${params.id}/print.json`);
};

export const printCarnetBaseMultiple = (params: IprintCarnetBaseMultiple) => {
  return baseInstance.patch(`${BASE_URL}/${params.id}/print_multiple.json`);
};

export const updateCarnetBase = (params: IupdateCarnetBase) => {
  const updateParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}.json`, { carnet_base: updateParams });
};
