// import { useBroadcast } from "use-broadcast-ts";

import { useCurrentOrdersStore } from "../../store";

import { IActivity, IOrder, IOrderStore } from "../../interfaces/storeInterfaces";

export const addActivitiesToOrder = (state: IOrderStore, activities: IActivity[], eventId: number, eventTitle: string) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  // Add new order if there is no current order
  if (currentOrdersStore.currentOrderId === -1 || order === undefined) {
    return createNewOrder(state, activities, eventId, eventTitle);
  }

  if (order) {
    // Creating new order if current order is for different event
    if (order.eventId && order.eventId !== eventId) {
      return createNewOrder(state, activities, eventId, eventTitle);
    }
    // Adding activities to existing order
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    updatedOrders[index].eventId = eventId;
    updatedOrders[index].eventTitle = eventTitle;
    if (updatedOrders[index].activities.length === 0) {
      updatedOrders[index].activities = activities;
    } else {
      activities.forEach((activity: IActivity) => {
        const activityIndex = updatedOrders[index].activities.findIndex((a: IActivity) => a.id === activity.id);
        if (activityIndex === -1) {
          updatedOrders[index].activities.push(activity);
        }
      });
    }
    return { orders: updatedOrders };
  }

  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  return { orders: updatedOrders };
};

export const removeActivityIdsFromOrder = (state: IOrderStore, activityIds: number[]) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.activities.length) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const activities = updatedOrders[index].activities;
    activityIds.forEach((activityId) => {
      const activityIndex = updatedOrders[index].activities.findIndex((activity: IActivity) => activity.id === activityId);
      if (activityIndex !== undefined && activityIndex !== -1) {
        updatedOrders[index].activities.splice(activityIndex, 1);
      }
    });

    // remove order if there are no sectors / products / seats / activities / voucher
    if (
      activities.length === 0 &&
      updatedOrders[index].products.length === 0 &&
      updatedOrders[index].selectedSeats.length === 0 &&
      updatedOrders[index].sectors.length === 0 &&
      updatedOrders[index].voucher === undefined
    ) {
      updatedOrders.splice(index, 1);
      useCurrentOrdersStore.setState((state) => {
        state.removeCurrentOrderId();
        return { ...state, currentOrderId: -1 };
      });
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

const createNewOrder = (state: IOrderStore, activities: IActivity[], eventId: number, eventTitle: string) => {
  const orderId = Date.now();
  useCurrentOrdersStore.setState((state) => {
    state.setCurrentOrderId(orderId);
    return { ...state, currentOrderId: orderId };
  });
  const order: IOrder = {
    id: orderId,
    eventId: eventId,
    eventTitle: eventTitle,
    activities: activities,
    products: [],
    reservationId: undefined,
    reservationSeats: undefined,
    sectors: [],
    seats: [],
    selectedSeats: [],
    voucher: undefined
  };
  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  updatedOrders.push(order);
  return { orders: updatedOrders };
};
