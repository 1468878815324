import { useCurrentOrdersStore } from "../../store";
import { IOrder, IOrderStore, ISector } from "../../interfaces/storeInterfaces";

export const addSectorToOrder = (state: IOrderStore, sector: ISector, eventId: number, eventTitle: string) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  // Add new order if there is no current order
  if (currentOrdersStore.currentOrderId === -1 || order === undefined) {
    return createNewOrder(state, sector, eventId, eventTitle);
  }

  if (order) {
    // Creating new order if current order is for different event
    if (order.eventId && order.eventId !== eventId) {
      return createNewOrder(state, sector, eventId, eventTitle);
    }
    // Adding sector to existing order
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    updatedOrders[index].eventId = eventId;
    updatedOrders[index].eventTitle = eventTitle;
    updatedOrders[index].sectors.push(sector);
    return { orders: updatedOrders };
  }

  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  return { orders: updatedOrders };
};

export const changeSectorQuantity = (state: IOrderStore, sectorId: number, quantity: number) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.sectors.length) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const sectors = updatedOrders[index].sectors;
    const sectorIndex = updatedOrders[index].sectors.findIndex((sector: ISector) => sector.id === sectorId);
    if (sectorIndex !== undefined && sectorIndex !== -1) {
      updatedOrders[index].sectors[sectorIndex].quantity = quantity;
    }
    // remove sector if quantity is 0
    if (sectors[sectorIndex].quantity === 0) {
      updatedOrders[index].eventId = undefined;
      updatedOrders[index].eventTitle = undefined;
      sectors.splice(sectorIndex, 1);
    }
    // remove order if there are no sectors / products / seats / activities / voucher
    if (
      sectors.length === 0 &&
      updatedOrders[index].products.length === 0 &&
      updatedOrders[index].selectedSeats.length === 0 &&
      updatedOrders[index].activities.length === 0 &&
      updatedOrders[index].voucher === undefined
    ) {
      updatedOrders.splice(index, 1);
      useCurrentOrdersStore.setState((state) => {
        state.removeCurrentOrderId();
        return { ...state, currentOrderId: -1 };
      });
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

export const decreaseSectorQuantity = (state: IOrderStore, sectorId: number) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.sectors.length) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const sectors = updatedOrders[index].sectors;
    const sectorIndex = sectors.findIndex((sector: ISector) => sector.id === sectorId);

    if (sectorIndex !== undefined && sectorIndex !== -1) {
      const isMinimumQuantity = sectors[sectorIndex] && sectors[sectorIndex].quantity <= 0;
      if (!isMinimumQuantity) sectors[sectorIndex].quantity--;
      // remove sector if quantity is 0
      if (sectors[sectorIndex].quantity === 0) {
        updatedOrders[index].eventId = undefined;
        updatedOrders[index].eventTitle = undefined;
        sectors.splice(sectorIndex, 1);
      }
      // remove order if there are no sectors / products / seats / activities / voucher
      if (
        sectors.length === 0 &&
        updatedOrders[index].products.length === 0 &&
        updatedOrders[index].selectedSeats.length === 0 &&
        updatedOrders[index].activities.length === 0 &&
        updatedOrders[index].voucher === undefined
      ) {
        updatedOrders.splice(index, 1);
        useCurrentOrdersStore.setState((state) => {
          state.removeCurrentOrderId();
          return { ...state, currentOrderId: -1 };
        });
      }
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

export const increaseSectorQuantity = (state: IOrderStore, sectorId: number, maxQuantity: number) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.sectors.length) {
    const updatedOrders = JSON.parse(JSON.stringify(state.orders));
    const index = updatedOrders.findIndex((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
    const sectorIndex = updatedOrders[index].sectors.findIndex((sector: ISector) => sector.id === sectorId);
    if (sectorIndex !== undefined && sectorIndex !== -1) {
      const sector = updatedOrders[index].sectors[sectorIndex];
      const isMaximumQuantity = sector && sector.quantity >= maxQuantity;
      if (!isMaximumQuantity) sector.quantity++;
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

export const removeSectorFromOrder = (state: IOrderStore, sectorId: number) => {
  const currentOrdersStore = useCurrentOrdersStore.getState();
  const order = state.orders.find((order: IOrder) => order.id === currentOrdersStore.currentOrderId);
  if (order && order.sectors.length) {
    const updatedOrders = [...state.orders];
    const index = updatedOrders.findIndex((order) => order.id === currentOrdersStore.currentOrderId);
    const sectorIndex = updatedOrders[index].sectors.findIndex((sector: ISector) => sector.id === sectorId);
    if (sectorIndex !== undefined && sectorIndex !== -1) {
      updatedOrders[index].sectors.splice(sectorIndex, 1);
    }
    return { orders: updatedOrders };
  }
  return { orders: [] };
};

const createNewOrder = (state: IOrderStore, sector: ISector, eventId: number, eventTitle: string) => {
  const orderId = Date.now();
  useCurrentOrdersStore.setState((state) => {
    state.setCurrentOrderId(orderId);
    return { ...state, currentOrderId: orderId };
  });
  const order: IOrder = {
    id: orderId,
    eventId: eventId,
    eventTitle: eventTitle,
    activities: [],
    products: [],
    reservationId: undefined,
    reservationSeats: undefined,
    sectors: [sector],
    seats: [],
    selectedSeats: [],
    voucher: undefined
  };
  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  updatedOrders.push(order);
  return { orders: updatedOrders };
};
