const PlaceholderReport = () => {
  return (
    <div className="w-72 h-48 bg-zinc-100 flex-col flex animate-pulse" data-testid="placeholder-report">
      <div className="w-full flex justify-center px-2 py-1 mt-1 mb-auto">
        <div className="rounded-md bg-zinc-300 mt-2 w-11/12 h-10"></div>
      </div>
      <div className="bg-zinc-300 w-11/12 h-10 mb-2 mx-auto rounded-md"></div>
      <div className="w-11/12 bg-zinc-300 h-6 mb-2 mx-auto rounded-md"></div>
    </div>
  );
};

export default PlaceholderReport;
