import { create } from "zustand";
import { shared } from "use-broadcast-ts";

import { updateCarnetOrderEmail, updateCarnetOrderPhoneNumber } from "./services/store/carnetOrders.service";
import {
  addPrepaidCarnetToReserveToCarnetOrder,
  addPrepaidCarnetsToReserveToCarnetOrder,
  changePrepaidCarnetToReserveCount,
  decreasePrepaidCarnetToReserveCount,
  increasePrepaidCarnetToReserveCount,
  removePrepaidCarnetToReserveFromCarnetOrder
} from "./services/store/prepaidCarnetsToReserve.service";
import { addProductToOrder, addProductsToOrder, changeProductQuantity, decreaseProductQuantity, increaseProductQuantity, removeProductFromOrder } from "./services/store/products.service";
import { addReservation } from "./services/store/reservations.service";
import { addSectorToOrder, changeSectorQuantity, decreaseSectorQuantity, increaseSectorQuantity, removeSectorFromOrder } from "./services/store/sectors.service";
import { addSelectedSeatToOrder, addSelectedSeatsToOrder, removeSelectedSeatFromOrder, removeSelectedSeatsFromOrder, updateSelectedSeatWithSeat } from "./services/store/selectedSeats.service";
import { addActivitiesToOrder, removeActivityIdsFromOrder } from "./services/store/activities.service";
import { addVoucherToOrder, removeVoucherFromOrder } from "./services/store/vouchers.service";

import {
  IActivity,
  IBaseStore,
  ICarnetOrder,
  ICarnetOrderStore,
  ICurrentOrdersStore,
  IOrder,
  IOrderStore,
  IPrepaidCarnetToReserve,
  IProduct,
  ISeat,
  ISecondDisplayStore,
  ISector,
  IselectedSeat
} from "./interfaces/storeInterfaces";
import { IOrderObject } from "./interfaces/orderInterfaces";
import { ICarnetBaseObject } from "./interfaces/carnetBaseInterfaces";
import { ICarnetOrderObject } from "./interfaces/carnetOrderInterfaces";
import { IProductOrderObject } from "./interfaces/productOrderInterfaces";
import { IPosNewInvoiceObject } from "./interfaces/posNewInvoiceInterfaces";
import { IEventObject } from "./interfaces/eventInterfaces";
import { IHallObject } from "./interfaces/hallInterfaces";
import { IVoucherObject } from "./interfaces/voucherInterfaces";

export const useBaseStore = create<IBaseStore>((set) => ({
  carnetBaseId: -1,
  carnetOrderId: -1,
  globalLoader: false,
  invoiceDownloadInfo: null,
  isNewOrder: false,
  orderId: -1,
  printLoaderInfo: null,
  printingProblemsActiveTab: null,
  printingProblemsOpen: false,
  productOrderId: -1,
  refresh: false,
  removeCarnetBaseId: () => set({ carnetBaseId: -1 }),
  removeCarnetOrderId: () => set({ carnetOrderId: -1 }),
  removeInvoiceDownloadInfo: () => set({ invoiceDownloadInfo: null }),
  removeOrderId: () => set({ orderId: -1 }),
  removePrintLoaderInfo: () => set({ printLoaderInfo: null }),
  removeProductOrderId: () => set({ productOrderId: -1 }),
  removeReportJobToken: () => set({ reportJobToken: null }),
  reportJobToken: null,
  setCarnetBaseId: (id: number) => set({ carnetBaseId: id }),
  setCarnetOrderId: (id: number) => set({ carnetOrderId: id }),
  setGlobalLoader: (value: boolean) => set({ globalLoader: value }),
  setInvoiceDownloadInfo: (id: number, type: "pos_invoice" | "carnet_pos_invoice", format: "html" | "pdf") => set({ invoiceDownloadInfo: { id: id, type: type, format: format } }),
  setIsNewOrder: (value: boolean) => set({ isNewOrder: value }),
  setOrderId: (id: number) => set({ orderId: id }),
  setPrintLoader: (json: {
    carnetBase?: ICarnetBaseObject;
    carnetOrder?: ICarnetOrderObject;
    order?: IOrderObject;
    ticketIds?: number[];
    productOrder?: IProductOrderObject;
    posNewInvoice?: IPosNewInvoiceObject;
    fiscal?: boolean;
    nonFiscal?: boolean;
    merge?: boolean;
  }) => set({ printLoaderInfo: json }),
  setPrintingProblemsActiveTab: (value: "orders" | "carnets" | "productOrders" | "carnetOrders" | null) => set({ printingProblemsActiveTab: value }),
  setPrintingProblemsOpen: (value: boolean) => set({ printingProblemsOpen: value }),
  setProductOrderId: (id: number) => set({ productOrderId: id }),
  setReportJobToken: (jobToken: string, format: "csv" | "html" | "pdf" | "xlsx") => set({ reportJobToken: { token: jobToken, format: format } }),
  setTerminalLoader: (value: boolean) => set({ terminalLoader: value }),
  setTerminalQuestionData: (questionData: any) => set({ terminalQuestionData: questionData }),
  terminalLoader: false,
  terminalQuestionData: null,
  toggleRefresh: () => set((state) => ({ refresh: !state.refresh }))
}));

export const useCarnetOrdersStore = create<ICarnetOrderStore>((set, get) => ({
  addCarnetOrder: (carnetOrder: ICarnetOrder) => set((state) => ({ carnetOrders: [...state.carnetOrders, carnetOrder] })),
  addPrepaidCarnetToReserveToCarnetOrder: (prepaidCarnetToReserve: IPrepaidCarnetToReserve) => set((state) => addPrepaidCarnetToReserveToCarnetOrder(state, prepaidCarnetToReserve)),
  addPrepaidCarnetsToReserveToCarnetOrder: (prepaidCarnetsToReserve: IPrepaidCarnetToReserve[]) => set((state) => addPrepaidCarnetsToReserveToCarnetOrder(state, prepaidCarnetsToReserve)),
  carnetOrders: [],
  changePrepaidCarnetToReserveCount: (prepaidCarnetTemplateId: number, count: number) => set((state) => changePrepaidCarnetToReserveCount(state, prepaidCarnetTemplateId, count)),
  decreasePrepaidCarnetToReserveCount: (prepaidCarnetTemplateId: number) => set((state) => decreasePrepaidCarnetToReserveCount(state, prepaidCarnetTemplateId)),
  fetchCarnetOrder: (id: number) => get().carnetOrders.find((carnetOrder) => carnetOrder.id === id),
  increasePrepaidCarnetToReserveCount: (prepaidCarnetTemplateId: number, maxCount: number) => set((state) => increasePrepaidCarnetToReserveCount(state, prepaidCarnetTemplateId, maxCount)),
  removeCarnetOrder: (id: number) => set((state) => ({ carnetOrders: state.carnetOrders.filter((carnetOrder) => carnetOrder.id !== id) })),
  removeCarnetOrders: () => set({ carnetOrders: [] }),
  removePrepaidCarnetToReserveFromCarnetOrder: (prepaidCarnetTemplateId: number) => set((state) => removePrepaidCarnetToReserveFromCarnetOrder(state, prepaidCarnetTemplateId)),
  updateCarnetOrder: (id: number, carnetOrder: ICarnetOrder) =>
    set((state) => {
      const index = state.carnetOrders.findIndex((carnetOrder) => carnetOrder.id === id);
      const updatedCarnetOrders = [...state.carnetOrders];
      updatedCarnetOrders[index] = carnetOrder;
      return { carnetOrders: updatedCarnetOrders };
    }),
  updateCarnetOrderEmail: (email: string) => set((state) => updateCarnetOrderEmail(state, email)),
  updateCarnetOrderPhoneNumber: (phoneNumber: string) => set((state) => updateCarnetOrderPhoneNumber(state, phoneNumber))
}));

export const useCurrentOrdersStore = create<ICurrentOrdersStore>(
  shared(
    (set) => ({
      currentCarnetOrderId: -1,
      currentOrderId: -1,
      removeCurrentCarnetOrderId: () => set({ currentCarnetOrderId: -1 }),
      removeCurrentOrderId: () => set({ currentOrderId: -1 }),
      setCurrentCarnetOrderId: (id: number) => set({ currentCarnetOrderId: id }),
      setCurrentOrderId: (id: number) => set({ currentOrderId: id })
    }),
    { name: "CurrentOrdersStore" }
  )
);

export const useOrdersStore = create<IOrderStore>(
  shared(
    (set, get) => ({
      orders: [],
      addActivitiesToOrder: (activities: IActivity[], eventId: number, eventTitle: string) => set((state) => addActivitiesToOrder(state, activities, eventId, eventTitle)),
      addOrder: (order: IOrder) => set((state) => ({ orders: [...state.orders, order] })),
      addProductToOrder: (product: IProduct) => set((state) => addProductToOrder(state, product)),
      addProductsToOrder: (products: IProduct[]) => set((state) => addProductsToOrder(state, products)),
      addReservation: (reservation: IOrderObject) => set((state) => addReservation(state, reservation)),
      addSectorToOrder: (sector: ISector, eventId: number, eventTitle: string) => set((state) => addSectorToOrder(state, sector, eventId, eventTitle)),
      addSelectedSeatToOrder: (selectedSeat: IselectedSeat, eventId: number, eventTitle: string, hallId?: number, hallWrapperElementId?: number, seat?: ISeat) =>
        set((state) => addSelectedSeatToOrder(state, selectedSeat, eventId, eventTitle, hallId, hallWrapperElementId, seat)),
      addSelectedSeatsToOrder: (selectedSeats: IselectedSeat[], eventId: number, eventTitle: string, hallId?: number, hallWrapperElementId?: number, seats?: ISeat[]) =>
        set((state) => addSelectedSeatsToOrder(state, selectedSeats, eventId, eventTitle, hallId, hallWrapperElementId, seats)),
      addVoucherToOrder: (voucher: IVoucherObject) => set((state) => addVoucherToOrder(state, voucher)),
      changeProductQuantity: (productId: number, quantity: number) => set((state) => changeProductQuantity(state, productId, quantity)),
      changeSectorQuantity: (sectorId: number, quantity: number) => set((state) => changeSectorQuantity(state, sectorId, quantity)),
      decreaseProductQuantity: (productId: number) => set((state) => decreaseProductQuantity(state, productId)),
      decreaseSectorQuantity: (sectorId: number) => set((state) => decreaseSectorQuantity(state, sectorId)),
      fetchOrder: (id: number) => get().orders.find((order) => order.id === id),
      increaseProductQuantity: (productId: number, maxQuantity: number) => set((state) => increaseProductQuantity(state, productId, maxQuantity)),
      increaseSectorQuantity: (sectorId: number, maxQuantity: number) => set((state) => increaseSectorQuantity(state, sectorId, maxQuantity)),
      removeActivityIdsFromOrder: (activityIds: number[]) => set((state) => removeActivityIdsFromOrder(state, activityIds)),
      removeOrder: (id: number) => set((state) => ({ orders: state.orders.filter((order) => order.id !== id) })),
      removeOrders: () => set({ orders: [] }),
      removeProductFromOrder: (productId: number) => set((state) => removeProductFromOrder(state, productId)),
      removeSectorFromOrder: (sectorId: number) => set((state) => removeSectorFromOrder(state, sectorId)),
      // removeSectorIdFromSelectedSeat: (selectedSeatId: number) => set((state) => removeSectorIdFromSelectedSeat(state, selectedSeatId)),
      removeSelectedSeatFromOrder: (selectedSeatId: number) => set((state) => removeSelectedSeatFromOrder(state, selectedSeatId)),
      removeSelectedSeatsFromOrder: () => set((state) => removeSelectedSeatsFromOrder(state)),
      removeVoucherFromOrder: () => set((state) => removeVoucherFromOrder(state)),
      updateOrder: (id: number, order: IOrder) =>
        set((state) => {
          const index = state.orders.findIndex((order) => order.id === id);
          const updatedOrders = [...state.orders];
          updatedOrders[index] = order;
          return { orders: updatedOrders };
        }),
      updateSelectedSeatWithSeat: (selectedSeatId: number, seat?: ISeat) => set((state) => updateSelectedSeatWithSeat(state, selectedSeatId, seat))
    }),
    { name: "OrdersStore" }
  )
);

export const useSecondDisplayStore = create<ISecondDisplayStore>(
  shared(
    (set) => ({
      blocked: false,
      event: null,
      hall: null,
      hallWrapperElementId: -1,
      removeEvent: () => set({ event: null }),
      removeHall: () => set({ event: null }),
      removeHallWrapperElementId: () => set({ hallWrapperElementId: -1 }),
      setBlocked: (value: boolean) => set({ blocked: value }),
      setEvent: (object: IEventObject | null) => set({ event: object }),
      setHall: (object: IHallObject | null) => set({ hall: object }),
      setHallWrapperElementId: (id: number) => set({ hallWrapperElementId: id })
    }),
    { name: "SecondDisplayStore" }
  )
);
