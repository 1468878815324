import { useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import { useBaseStore, useCurrentOrdersStore, useOrdersStore, useSecondDisplayStore } from "../store";
import { useCooperator } from "../hooks";

import { createProductOrder } from "../services/objects/productOrders.service";
import { handleCreateOrderResponse } from "../services/responseDataHandler";
import { createOrder } from "../services/objects/orders.service";

import { IActivity, IProduct, ISeat, ISector } from "../interfaces/storeInterfaces";
import { IcreateOrder, IcreateOrderProductsCart, IcreateOrderSeats, IcreateOrderSectors } from "../interfaces/orderInterfaces";

const useCreateOrder = () => {
  const { t } = useTranslation();

  const cooperatorId = useCooperator()[0];
  const currentOrderId = useCurrentOrdersStore((state) => state.currentOrderId);
  const removeCurrentOrderId = useCurrentOrdersStore((state) => state.removeCurrentOrderId);
  const setIsNewOrder = useBaseStore((state) => state.setIsNewOrder);
  const setGlobalLoader = useBaseStore((state) => state.setGlobalLoader);
  const setOrderId = useBaseStore((state) => state.setOrderId);
  const setProductOrderId = useBaseStore((state) => state.setProductOrderId);
  const order = useOrdersStore((state) => state.fetchOrder(currentOrderId));
  const removeOrder = useOrdersStore((state) => state.removeOrder);
  const setBlocked = useSecondDisplayStore((state) => state.setBlocked);

  const [loading, setLoading] = useState<boolean>(false);

  const prepareProducts = (products: IProduct[]) => {
    return products.reduce((result, product) => {
      result[product.id.toString()] = {
        product_id: product.id,
        name: product.name,
        short_name: product.name,
        price: product.price.toString(),
        vat: product.vat,
        ean: product.ean || "",
        product_type: product.productType,
        additional_info: {},
        quantity: product.quantity,
        available: product.maxQuantity // TODO: check if it is correct
      };
      return result;
    }, {} as IcreateOrderProductsCart);
  };

  const prepareSeats = (seats: ISeat[]) => {
    return seats.reduce((result, seat) => {
      result[seat.viewId.toString()] = { sector_id: seat.sectorId, state: "ordering" };
      return result;
    }, {} as IcreateOrderSeats);
  };

  const prepareSectors = (sectors: ISector[]) => {
    return sectors.reduce((result, sector) => {
      result[sector.id.toString()] = sector.quantity.toString();
      return result;
    }, {} as IcreateOrderSectors);
  };

  const prepareActivities = (activities: IActivity[]) => {
    return activities.reduce((result, sector) => {
      result[sector.id.toString()] = sector.quantity.toString();
      return result;
    }, {} as IcreateOrderSectors);
  };

  const handleCorrectOrderCreated = (id: number) => {
    removeOrder(currentOrderId);
    removeCurrentOrderId();
    setIsNewOrder(true);
    setOrderId(id);
  };

  const handleCorrectProductOrderCreated = (id: number) => {
    removeOrder(currentOrderId);
    removeCurrentOrderId();
    setIsNewOrder(true);
    setProductOrderId(id);
  };

  const handleCreateOrder = () => {
    if (!order) return;

    let orderParams = { order: { phone_number: "" }, event_id: order.eventId, voucher_token: order.voucher?.token || "" } as IcreateOrder;
    if (order.reservationId) {
      orderParams = { ...orderParams, old_order: order.reservationId };
    }
    if (cooperatorId && cooperatorId !== -1) orderParams.cooperator_id = cooperatorId;

    if (order.products.length > 0) {
      orderParams.products_cart = JSON.stringify(prepareProducts(order.products));
    }

    if (order.sectors.length > 0) {
      orderParams.order.sectors = prepareSectors(order.sectors);
    } else if (order.activities.length > 0) {
      orderParams.order.sectors = prepareActivities(order.activities);
    } else if (order.seats.length > 0 && order.seats.length === order.selectedSeats.length) {
      if (order.hallWrapperElementId) {
        orderParams.hall_wrapper_element_id = order.hallWrapperElementId;
      }
      orderParams.order.seats = prepareSeats(order.seats);
    } else if (order.products.length > 0 && orderParams.products_cart && order.selectedSeats.length === 0) {
      setLoading(true);
      setGlobalLoader(true);
      createProductOrder({ products_cart: orderParams.products_cart }).then((response) =>
        handleCreateOrderResponse({
          response: response,
          enqueueSnackbar: enqueueSnackbar,
          setLoading: setLoading,
          setGlobalLoader: setGlobalLoader,
          handleCorrectOrderCreated: handleCorrectProductOrderCreated
        })
      );
      return;
    } else if (order.selectedSeats.length > 0 && order.seats.length !== order.selectedSeats.length) {
      enqueueSnackbar(t("errors.must_select_tickets_to_all_selected_seats"), { variant: "error" });
      setLoading(false);
      return;
    } else {
      enqueueSnackbar(t("errors.select_tickets_or_products_to_procced"), { variant: "error" });
      setLoading(false);
      return 'ERROR: "order.sectors.length === 0 && order.seats.length === 0 && order.products.length === 0"';
    }

    setLoading(true);
    setGlobalLoader(true);
    setBlocked(true);
    createOrder(orderParams).then((response) =>
      handleCreateOrderResponse({
        response: response,
        enqueueSnackbar: enqueueSnackbar,
        setLoading: setLoading,
        setGlobalLoader: setGlobalLoader,
        handleCorrectOrderCreated: handleCorrectOrderCreated
      })
    );
  };
  return { handleCreateOrder, loading };
};
export default useCreateOrder;
