import { baseInstance } from "../../axiosCashier";
import { IcreateEkoMessage, IfetchEkoMessage, IfetchEkoMessages } from "../../interfaces/ekoMessageInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/eko_messages";

export const createEkoMessage = (params: IcreateEkoMessage) => {
  const { content, taskable_id, taskable_type } = params;
  const requestParams = { eko_message: { content: content }, taskable_id: taskable_id, taskable_type: taskable_type };
  return baseInstance.post(`${BASE_URL}.json`, requestParams);
};

export const fetchEkoMessage = (params: IfetchEkoMessage) => {
  const queryParams = fetchQueryParams(Object.fromEntries(Object.entries(params).filter(([key]) => key !== "id")), ["taskable_id"]);
  return baseInstance.get(`${BASE_URL}/${params.id}.json${queryParams}`);
};

export const fetchEkoMessages = (params: IfetchEkoMessages) => {
  const queryParams = fetchQueryParams(params, ["taskable_id"]);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};
