import { ICarnetBaseObject } from "../interfaces/carnetBaseInterfaces";
import { ICarnetOrderObject } from "../interfaces/carnetOrderInterfaces";
import { IOrderObject } from "../interfaces/orderInterfaces";
import { IPosNewInvoiceObject } from "../interfaces/posNewInvoiceInterfaces";
import { IProductOrderObject } from "../interfaces/productOrderInterfaces";
import { ITicketObject } from "../interfaces/ticketInterfaces";
import { IfiscalInvoicePrintPrintData, IfiscalNonFiscalPrintCustomLine, IfiscalPrintPrintData, InonFiscalPrintPrintData } from "../interfaces/printerInterfaces";
import { currencyFormat, dateFormatWithTime, limitStringToPaperWidth, numberInWords, stripPolishCharacters } from "./commons";
import { IPosNewInvoicePositionObject } from "../interfaces/posNewInvoicePositionInterfaces";
import { IterminalPrintData } from "../interfaces/terminalInterfaces";
import {
  handleFiscalNonFiscalTerminalPrintDataForOfflineReport,
  handleFiscalNonFiscalTerminalPrintDataForSettlementReport,
  handleFiscalNonFiscalTerminalPrintDataForTransaction,
  handleFiscalNonFiscalTerminalPrintDataForUpdateResult
} from "./printerTerminalCommons";
import { convertImage } from "./logoPrinter.service";
import { getCashierLogotypes } from "./objects/cashiers.service";
import { image } from "d3";
import { ILogotypesObject } from "../interfaces/reservationInterfaces";

// INVOICE PRINT
export const handleFiscalPosNewInvoiceData = (posNewInvoice: IPosNewInvoiceObject) => {
  return {
    info: {
      invoiceNr: posNewInvoice.display_number,
      clientNip: posNewInvoice.buyer_tax_id,
      paymentDeadline: posNewInvoice.payment_deadline,
      clientName: stripPolishCharacters(posNewInvoice.buyer_name || "", false),
      paymentType: stripPolishCharacters(posNewInvoice.payment_method || "", false),
      cashierCode: "",
      clientAdress: [stripPolishCharacters(posNewInvoice.buyer_address || "", false), stripPolishCharacters(`${posNewInvoice.buyer_postal_code} ${posNewInvoice.buyer_city}`, false)]
    },
    lines: handlePosNewInvoiceLines(posNewInvoice)
  } as IfiscalInvoicePrintPrintData;
};

// data handlers
const handlePosNewInvoiceLines = (posNewInvoice: IPosNewInvoiceObject) => {
  if (!posNewInvoice.positions) return [];

  return posNewInvoice.positions?.map((position) => {
    const price = parseFloat(position.unit_price);
    const quantity = parseFloat(position.quantity);

    return {
      type: "item",
      name: stripPolishCharacters(`${position.name} ${position.vat_rate}`, false),
      vatRate: parseFloat(position.vat_rate),
      quantity: quantity,
      price: price,
      totalPrice: quantity * price
    };
  });
};
// INVOICE PRINT

// FISCAL NON FISCAL INVOICE PRINT
export const handleFiscalNonFiscalPosNewInvoiceLines = (posNewInvoice: IPosNewInvoiceObject, polishCharacters: boolean, paperWidth: 55 | 80) => {
  const positions = posNewInvoice.positions || [];
  const totalAmount = parseFloat(posNewInvoice.total_amount || "").toFixed(2);
  let customLines = [
    { type: "text_line", text: "FAKTURA", fontSize: 1, centerText: 1 },
    { type: "text_line", text: `Numer: ${posNewInvoice.display_number}`, fontSize: 0, centerText: 0 },
    { type: "text_line", text: stripPolishCharacters(`Data wystawienia: ${posNewInvoice.date_of_issue}`, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "text_line", text: stripPolishCharacters(`Data sprzedazy: ${posNewInvoice.date_of_sale}`, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "text_line", text: stripPolishCharacters(`Termin płatności: ${posNewInvoice.payment_deadline}`, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "text_line", text: stripPolishCharacters(`Forma płatności: ${posNewInvoice.payment_method}`, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "dot_line" },
    { type: "text_line", text: "Sprzedawca: ", fontSize: 0, centerText: 0 },
    ...limitStringToPaperWidth(stripPolishCharacters(posNewInvoice.seller_name || "", polishCharacters), paperWidth),
    ...limitStringToPaperWidth(stripPolishCharacters(`${posNewInvoice.seller_address} ${posNewInvoice.seller_postal_code} ${posNewInvoice.seller_city}`, polishCharacters), paperWidth),
    { type: "text_line", text: `NIP: ${posNewInvoice.seller_tax_id}`, fontSize: 0, centerText: 0 },
    { type: "dot_line" },
    { type: "text_line", text: "Nabywca: ", fontSize: 0, centerText: 0 },
    ...limitStringToPaperWidth(stripPolishCharacters(posNewInvoice.buyer_name || "", polishCharacters), paperWidth),
    ...limitStringToPaperWidth(stripPolishCharacters(`${posNewInvoice.buyer_address} ${posNewInvoice.buyer_postal_code} ${posNewInvoice.buyer_city}`, polishCharacters), paperWidth),
    { type: "text_line", text: `NIP: ${posNewInvoice.buyer_tax_id}`, fontSize: 0, centerText: 0 },
    { type: "dot_line" },
    { type: "text_line", text: "Odbiorca: ", fontSize: 0, centerText: 0 },
    ...limitStringToPaperWidth(stripPolishCharacters(posNewInvoice.receiver_name || "", polishCharacters), paperWidth),
    ...limitStringToPaperWidth(stripPolishCharacters(`${posNewInvoice.receiver_address} ${posNewInvoice.receiver_postal_code} ${posNewInvoice.receiver_city}`, polishCharacters), paperWidth),
    { type: "text_line", text: `NIP: ${posNewInvoice.receiver_tax_id}`, fontSize: 0, centerText: 0 },
    { type: "dot_line" },
    { type: "text_line", text: stripPolishCharacters("Nazwa  Ilość*Cena Jedn.Brutto", polishCharacters), fontSize: 0, centerText: 0 },
    handleFiscalNonFiscallPosNewInvoiceFourInLine(["Wart.Netto", "Stawka", "Podatek", "Wart.Brutto"], paperWidth),
    { type: "dot_line" },
    ...positions
      .map((position) => [
        ...limitStringToPaperWidth(stripPolishCharacters(`${position.name} ${position.quantity}*${position.unit_price}`, polishCharacters), paperWidth),
        handleFiscalNonFiscallPosNewInvoiceFourInLine(
          [
            parseFloat(position.price_net).toFixed(2),
            handleVatRateDisplayValue(position.vat_rate),
            parseFloat(position.price_tare).toFixed(2),
            (parseFloat(position.quantity) * parseFloat(position.unit_price)).toFixed(2)
          ],
          paperWidth
        )
      ])
      .flat(Infinity),
    { type: "dot_line" },
    handleFiscalNonFiscallPosNewInvoiceFourInLine(["Stawka", "Wart. Netto", "Wart. VAT", "Wart.Brutto"], paperWidth),
    ...handleFiscalNonFiscalPosNewInvoicePositionsInGroups(positions, paperWidth),
    { type: "dot_line" },
    handleFiscalNonFiscallPosNewInvoiceFourInLine(
      ["", parseFloat(posNewInvoice.total_amount_net || "").toFixed(2), parseFloat(posNewInvoice.total_amount_tare || "").toFixed(2), totalAmount],
      paperWidth
    ),
    { type: "dot_line" },
    { type: "text_line", text: `SUMA:${" ".repeat(12 - (totalAmount.length || 0))}PLN ${totalAmount}`, fontSize: 1, centerText: 0 },
    { type: "text_line", text: stripPolishCharacters("Słownie:", polishCharacters), fontSize: 0, centerText: 0 },
    ...limitStringToPaperWidth(stripPolishCharacters(numberInWords(parseFloat(posNewInvoice.total_amount || "")), polishCharacters), paperWidth)
  ];

  return customLines as IfiscalNonFiscalPrintCustomLine[];
};

// data handlers
const handleVatRateDisplayValue = (vatRate: string | number) => {
  const parsedVatRate = parseFloat(String(vatRate)).toFixed(0);
  return parsedVatRate === "-1" ? "zw:" : `${parsedVatRate}%:`;
};

const handleVatRateDisplayValueForLineItems = (vatRate: string | number) => {
  const parsedVatRate = parseFloat(String(vatRate)).toFixed(0);
  return parsedVatRate === "-1" ? "zw" : `${parsedVatRate}`;
};

const handleFiscalNonFiscallPosNewInvoiceFourInLine = (strings: string[], paperWidth: 55 | 80) => {
  let text = "";
  if (strings.length !== 4) return { type: "text_line", text: text, fontSize: 0, centerText: 0 };

  let firstPartLength = paperWidth === 55 ? 19 : 25;
  let secondPartLength = paperWidth === 55 ? 11 : 15;
  let thirdPartLength = paperWidth === 55 ? 12 : 16;
  const [first, second, third, fourth] = strings;
  text = `${first}${" ".repeat(firstPartLength - first.length - second.length)}${second}${" ".repeat(secondPartLength - third.length)}${third}${" ".repeat(thirdPartLength - fourth.length)}${fourth}`;
  return { type: "text_line", text: text, fontSize: 0, centerText: 0 };
};

const handleFiscalNonFiscalPosNewInvoicePositionsInGroups = (positions: IPosNewInvoicePositionObject[], paperWidth: 55 | 80) => {
  const groupedByVatRate = positions.reduce((acc: { [key: string]: { netSum: number; tareSum: number; unitPriceSum: number } }, position) => {
    const { vat_rate, price_net, price_tare, unit_price, quantity } = position;
    const netSum = parseFloat(price_net) * parseFloat(quantity);
    const tareSum = parseFloat(price_tare) * parseFloat(quantity);
    const unitPriceSum = parseFloat(unit_price) * parseFloat(quantity);

    if (!acc[vat_rate]) {
      acc[vat_rate] = {
        netSum: 0,
        tareSum: 0,
        unitPriceSum: 0
      };
    }

    acc[vat_rate].netSum += netSum;
    acc[vat_rate].tareSum += tareSum;
    acc[vat_rate].unitPriceSum += unitPriceSum;

    return acc;
  }, {});

  return Object.entries(groupedByVatRate).map(([vat_rate, sums]) => {
    const { netSum, tareSum, unitPriceSum } = sums;
    return handleFiscalNonFiscallPosNewInvoiceFourInLine([handleVatRateDisplayValue(vat_rate), netSum.toFixed(2), tareSum.toFixed(2), unitPriceSum.toFixed(2)], paperWidth);
  });
};
// FISCAL NON FISCAL INVOICE PRINT

// FISCAL NON FISCAL PRINT
export const handleFiscalNonFiscalCarnetBaseData = (carnetBase: ICarnetBaseObject, polishCharacters: boolean, paperWidth: 55 | 80, barcode?: string) => {
  const barcodeToUse = barcode || carnetBase.barcode || "";
  const carnetData = {
    infinite_number_of_days: carnetBase.infinite_number_of_days ? stripPolishCharacters("Brak ograniczeń", polishCharacters) : carnetBase.number_of_days,
    infinite_number_of_tickets: carnetBase.infinite_number_of_tickets ? stripPolishCharacters("Brak ograniczeń", polishCharacters) : carnetBase.number_of_tickets,
    infinite_amount: carnetBase.infinite_amount ? stripPolishCharacters("Brak ograniczeń", polishCharacters) : carnetBase.amount,
    infinite_deadline: carnetBase.infinite_deadline ? stripPolishCharacters("Brak ograniczeń", polishCharacters) : dateFormatWithTime(carnetBase.deadline || "", true)
  };
  return [
    ...limitStringToPaperWidth(stripPolishCharacters(carnetBase.name, polishCharacters), paperWidth, 1),
    { type: "qr_code", text: barcodeToUse },
    { type: "text_line", text: barcodeToUse.replace(/\d{3}(?=\d)/g, "$& "), fontSize: 1, centerText: 1 },
    ...limitStringToPaperWidth(stripPolishCharacters(`Dane: ${carnetBase.first_name} ${carnetBase.last_name}`, polishCharacters), paperWidth, 1),
    ...limitStringToPaperWidth(stripPolishCharacters(`Dni: ${carnetData.infinite_number_of_days}`, polishCharacters), paperWidth, 1),
    ...limitStringToPaperWidth(stripPolishCharacters(`Bilety: ${carnetData.infinite_number_of_tickets}`, polishCharacters), paperWidth, 1),
    ...limitStringToPaperWidth(stripPolishCharacters(`Wartość: ${carnetData.infinite_amount}`, polishCharacters), paperWidth, 1),
    ...limitStringToPaperWidth(stripPolishCharacters(`Ważny do: ${carnetData.infinite_deadline}`, polishCharacters), paperWidth, 1)
  ] as IfiscalNonFiscalPrintCustomLine[];
};

export const handleFiscalNonFiscalOrderData = (order: IOrderObject, polishCharacters: boolean, paperWidth: 55 | 80, ticket?: ITicketObject, selectedTickets?: ITicketObject[]) => {
  const barcodeToUse = handleBarcodeInformation(order, ticket, selectedTickets)[0];
  const seatDescToUse = handleSeatDescInformation(order, ticket, selectedTickets);
  const holderNameToUse = handleHolderInformation(order, ticket, selectedTickets) || "";

  let outputArr = [];
  outputArr.push(...limitStringToPaperWidth(handleInfoInformation(order, polishCharacters, ticket, selectedTickets), paperWidth, 1));
  outputArr.push(
    { type: "qr_code", text: barcodeToUse },
    { type: "text_line", text: barcodeToUse.replace(/\d{3}(?=\d)/g, "$& "), fontSize: 1, centerText: 1 }
  );

  if (order.hall_name){
    outputArr.push({ type: "text_line", text: order.hall_name, fontSize: 1, centerText: 1 })
  }
  
  seatDescToUse.map((seatDesc) =>
    outputArr.push({ type: "text_line", text: seatDesc, fontSize: 1, centerText: 1 })
  )
  outputArr.push(...limitStringToPaperWidth(stripPolishCharacters(holderNameToUse, polishCharacters), paperWidth, 1));

  return outputArr as IfiscalNonFiscalPrintCustomLine[];
  // return [
  //   ...limitStringToPaperWidth(handleInfoInformation(order, polishCharacters, ticket, selectedTickets), paperWidth, 1),
  //   { type: "qr_code", text: barcodeToUse },
  //   { type: "text_line", text: barcodeToUse.replace(/\d{3}(?=\d)/g, "$& "), fontSize: 1, centerText: 1 },
  //   { type: "text_line", text: seatDescToUse, fontSize: 1, centerText: 1 },
  //   { type: "empty_line" },
  //   ...limitStringToPaperWidth(stripPolishCharacters(holderNameToUse, polishCharacters), paperWidth, 1)
  // ] as IfiscalNonFiscalPrintCustomLine[];
};

export const handleFiscalNonFiscalTerminalPrintData = (terminalPrintData: IterminalPrintData, polishCharacters: boolean, paperWidth: 55 | 80) => {
  let customLines: any[] = [];

  switch (terminalPrintData.Layout) {
    case "OfflineReport":
      // console.log("OfflineReport terminalPrintData", terminalPrintData);
      customLines = handleFiscalNonFiscalTerminalPrintDataForOfflineReport(terminalPrintData, polishCharacters, paperWidth);
      break;
    case "SettlementReport":
      // console.log("SettlementReport terminalPrintData", terminalPrintData);
      customLines = handleFiscalNonFiscalTerminalPrintDataForSettlementReport(terminalPrintData, polishCharacters, paperWidth);
      break;
    case "Transaction":
      customLines = handleFiscalNonFiscalTerminalPrintDataForTransaction(terminalPrintData, polishCharacters, paperWidth);
      break;
    case "UpdateResult":
      customLines = handleFiscalNonFiscalTerminalPrintDataForUpdateResult(terminalPrintData, polishCharacters, paperWidth);
      break;
  }

  return customLines as IfiscalNonFiscalPrintCustomLine[];
};
// FISCAL NON FISCAL PRINT

// FISCAL PRINT
const maxLineStringlenght = 43;

export const handleFiscalCarnetBaseData = (carnetBase: ICarnetBaseObject) => {
  const quantityToUse = carnetBase.barcodes?.length || 1;
  const price = parseFloat(carnetBase.price);
  const paymentType = stripPolishCharacters(carnetBase.payment_desc || "cash", false);

  return {
    info: {
      paymentType: paymentType,
      paymentStringType: handlePaymentStringType(paymentType),
      clientNip: carnetBase.client_nip || ""
    },
    lines: [
      {
        type: "item",
        name: stripPolishCharacters(`${carnetBase.name.substring(0,maxLineStringlenght)} ${handleVatRateDisplayValueForLineItems(carnetBase.vat)}`, false),
        vatRate: carnetBase.vat ?? 23,
        quantity: quantityToUse,
        price: price,
        totalPrice: quantityToUse * price
      }
    ]
  } as IfiscalPrintPrintData;
};

export const handleFiscalCarnetOrderData = (carnetOrder: ICarnetOrderObject) => {
  const paymentType = stripPolishCharacters(carnetOrder.dotpay_desc || "cash", false);
  return {
    info: {
      paymentType: paymentType,
      paymentStringType: handlePaymentStringType(paymentType),
      clientNip: carnetOrder.client_nip || ""
    },
    lines: handleCarnetOrderLines(carnetOrder)
  } as IfiscalPrintPrintData;
};

export const handleFiscalOrderData = (order: IOrderObject) => {
  const paymentType = stripPolishCharacters(order.dotpay_desc || "cash", false);

  return {
    info: {
      paymentType: paymentType,
      paymentStringType: handlePaymentStringType(paymentType),
      clientNip: order.client_nip || ""
    },
    lines: handleOrderLines(order)
  } as IfiscalPrintPrintData;
};

export const handleFiscalProductOrderData = (productOrder: IProductOrderObject) => {
  const paymentType = stripPolishCharacters(productOrder.payment_type || "cash", false);

  return {
    info: {
      paymentType: paymentType,
      paymentStringType: handlePaymentStringType(paymentType),
      clientNip: productOrder.client_nip || ""
    },
    lines: handleProductOrderLines(productOrder)
  } as IfiscalPrintPrintData;
};

// data handlers
const handlePaymentStringType = (paymentType: string) => {
  switch (paymentType) {
    case "cash":
      return "Gotowka";
    case "card":
      return "Karta";
    default:
      return paymentType;
  }
};

const handleCarnetOrderLines = (carnetOrder: ICarnetOrderObject) => {
  let lines: { type: string; name: string; vatRate: number; quantity: number; price: number; totalPrice: number }[] = [];
  if (!carnetOrder.prepaid_carnets) return lines;

  const groupedPrepaidCarnets = carnetOrder.prepaid_carnets.reduce((result: { [key: string]: { quantity: number; price: number; amount: number; vatRate: number } }, ticket) => {
    const { name, price, vat_rate } = ticket;
    if (!result[name]) {
      result[name] = { quantity: 0, price: parseFloat(price), amount: 0, vatRate: vat_rate ?? 23 };
    }
    result[name].quantity++;
    result[name].amount += parseFloat(price);

    return result;
  }, {});

  return Object.entries(groupedPrepaidCarnets).map(([name, { quantity, price, amount, vatRate }]) => {
    return { type: "item", name: stripPolishCharacters(`${name.substring(0,maxLineStringlenght)} ${handleVatRateDisplayValueForLineItems(vatRate)}`, false), vatRate: vatRate, quantity: quantity, price: price, totalPrice: amount };
  });
};

const handleOrderLines = (order: IOrderObject) => {
  let lines: { type: string; name: string; vatRate: number; quantity: number; price: number; totalPrice: number }[] = [];
  if (!order.tickets) return lines;

  const groupedTickets = order.tickets.reduce((result: { [key: string]: { quantity: number; price: number; amount: number; vatRate: number } }, ticket) => {
    const { ticket_typ, total_price, vat_rate } = ticket;
    if (!result[ticket_typ]) {
      result[ticket_typ] = { quantity: 0, price: parseFloat(total_price), amount: 0, vatRate: vat_rate ?? 23 };
    }
    result[ticket_typ].quantity++;
    result[ticket_typ].amount += parseFloat(total_price);

    return result;
  }, {});
  lines = Object.entries(groupedTickets).map(([ticketTyp, { quantity, price, amount, vatRate }]) => {
    return {
      type: "item",
      name: stripPolishCharacters(`${ticketTyp.substring(0,maxLineStringlenght)} ${handleVatRateDisplayValueForLineItems(vatRate)}`, false),
      vatRate: vatRate,
      quantity: quantity,
      price: price,
      totalPrice: amount
    };
  });
  order.line_items?.forEach((lineItem) => {
    lines.push({
      type: "item",
      name: stripPolishCharacters(`${lineItem.name.substring(0,maxLineStringlenght)} ${lineItem.vat}`, false),
      vatRate: lineItem.vat,
      quantity: lineItem.quantity,
      price: parseFloat(lineItem.price),
      totalPrice: parseFloat(lineItem.amount || "")
    });
  });

  return lines;
};

const handleProductOrderLines = (productOrder: IProductOrderObject) => {
  if (!productOrder.line_items) return [];

  return productOrder.line_items?.map((lineItem) => {
    return {
      type: "item",
      name: stripPolishCharacters(`${lineItem.name.substring(0,maxLineStringlenght)} ${handleVatRateDisplayValueForLineItems(lineItem.vat)}`, false),
      vatRate: lineItem.vat,
      quantity: lineItem.quantity,
      price: parseFloat(lineItem.price),
      totalPrice: parseFloat(lineItem.amount || "")
    };
  });
};
// FISCAL PRINT

// NON FISCAL PRINT
export const handleNonFiscalCarnetBaseData = (carnetBase: ICarnetBaseObject, polishCharacters: boolean, barcode?: string, logotypes?: ILogotypesObject) => {
  const customPrinterMessages = carnetBase.custom_printer_messages || { line1: "", line2: "", line3: "", line4: "", line5: "" };

  return {
    object: {
      title: stripPolishCharacters(carnetBase.name, polishCharacters),
      address: "",
      hallName: handleNamedCarnetBase(carnetBase, polishCharacters),
      date: dateFormatWithTime(carnetBase.created_at, true)
    },
    user: {
      name: stripPolishCharacters(carnetBase.user_name || "", polishCharacters)
    },
    printType: "Karnet",
    info: stripPolishCharacters(`${carnetBase.name}, ${currencyFormat(carnetBase.price)}`, polishCharacters).substring(0,maxLineStringlenght),
    carnetData: {
      infinite_number_of_days: carnetBase.infinite_number_of_days ? stripPolishCharacters("Brak ograniczeń", polishCharacters) : carnetBase.number_of_days,
      infinite_number_of_tickets: carnetBase.infinite_number_of_tickets ? stripPolishCharacters("Brak ograniczeń", polishCharacters) : carnetBase.number_of_tickets,
      infinite_amount: carnetBase.infinite_amount ? stripPolishCharacters("Brak ograniczeń", polishCharacters) : carnetBase.amount,
      infinite_deadline: carnetBase.infinite_deadline ? stripPolishCharacters("Brak ograniczeń", polishCharacters) : dateFormatWithTime(carnetBase.deadline || "", true)
    },
    lines: handleCustomPrinterMessages(customPrinterMessages, polishCharacters),
    barcode: [barcode || carnetBase.barcode || ""],
    logotypes: logotypes
  } as InonFiscalPrintPrintData;
};

export const handleFetchLogotypes = async (logo_type: string, print_type: string) => {
  async function convertBlobToBase64(blob: Blob): Promise<string> {
    const reader = new FileReader();
  
    reader.readAsDataURL(blob);
  
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }

  try {
    const params = { logo_type: logo_type };
    const response = await getCashierLogotypes(params);
    const imageBlob = response.data;
    const base64Image = await convertBlobToBase64(imageBlob);
    
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = base64Image; // Set the Base64 string as the image source
      img.onload = function () {
        
        if(print_type !== "PDF"){
          const getImageData = convertImage(img);
          const imageData = getImageData();
          resolve(imageData); // Resolve with the image data
        }else{
          resolve(base64Image)
        }
      };
      img.onerror = function (error) {
        reject(error); // Reject if there's an error loading the image
      };
    });
  } catch (error) {
    console.error('Error fetching and displaying the image:', error);
    throw error; // Rethrow the error to propagate it up the call stack
  }
}

export const handleNonFiscalOrderData = (order: IOrderObject, polishCharacters: boolean, ticket?: ITicketObject, selectedTickets?: ITicketObject[], logotypes?: ILogotypesObject) => {
  const customPrinterMessages = order.custom_printer_messages || { line1: "", line2: "", line3: "", line4: "", line5: "" };
  
  return {
    object: {
      title: stripPolishCharacters(order.event_title || "", polishCharacters),
      address: stripPolishCharacters(order.event_address || "", polishCharacters),
      hallName: stripPolishCharacters(order.hall_name || "", polishCharacters),
      date: order.event_formatted_store_date,
      holder_name: handleHolderInformation(order, ticket, selectedTickets)
    },
    user: {
      name: stripPolishCharacters(order.user_name || "", polishCharacters)
    },
    printType: "Bilet",
    info: handleInfoInformation(order, polishCharacters, ticket, selectedTickets).substring(0,maxLineStringlenght),
    row: handleRowInformation(order, polishCharacters, ticket, selectedTickets),
    seat: undefined, // ??? [1, 2, 3]
    seatEditor: order.hall_name ? true : false, // ??? fetch data from api?
    lines: handleCustomPrinterMessages(customPrinterMessages, polishCharacters),
    barcode: handleBarcodeInformation(order, ticket, selectedTickets),
    logotypes: logotypes

  } as InonFiscalPrintPrintData;
};

// data handlers
const handleBarcodeInformation = (order: IOrderObject, ticket?: ITicketObject, selectedTickets?: ITicketObject[]) => {
  const tickets = selectedTickets || order.tickets;
  let barcode = "";
  if (ticket) {
    barcode = ticket.barcode;
  } else if (tickets) {
    barcode = tickets[0].barcode;
  }

  return [barcode];
};

const handleHolderInformation = (order: IOrderObject, ticket?: ITicketObject, selectedTickets?: ITicketObject[]) => {
  const tickets = selectedTickets || order.tickets;
  let holder_name = "";
  if (ticket && ticket.holder_informations) {
    holder_name = ticket.holder_informations.name!;
  } else if (tickets && tickets[0].holder_informations) {
    holder_name = tickets[0].holder_informations.name!;
  }

  return holder_name;
};

const handleSeatDescInformation = (order: IOrderObject, ticket?: ITicketObject, selectedTickets?: ITicketObject[]) => {
  const tickets = selectedTickets || order.tickets;
  let seatDescArr = [];
  if (ticket) {
    seatDescArr.push(ticket.spot_desc || "");
  } else if (tickets) {
    tickets.map(singleTicket => seatDescArr.push(singleTicket.spot_desc))
  }

  return seatDescArr;
};

const handleCustomPrinterMessages = (customPrinterMessages: { [key: string]: string }, polishCharacters: boolean) => {
  return [
    stripPolishCharacters(customPrinterMessages.line1 || "", polishCharacters),
    stripPolishCharacters(customPrinterMessages.line2 || "", polishCharacters),
    stripPolishCharacters(customPrinterMessages.line3 || "", polishCharacters),
    stripPolishCharacters(customPrinterMessages.line4 || "", polishCharacters),
    stripPolishCharacters(customPrinterMessages.line5 || "", polishCharacters)
  ];
};

const handleInfoInformation = (order: IOrderObject, polishCharacters: boolean, ticket?: ITicketObject, selectedTickets?: ITicketObject[]) => {
  const tickets = selectedTickets || order.tickets;
  let info = "";
  if (ticket) {
    info = `${ticket.ticket_typ}, ${currencyFormat(ticket.price)}`;
  } else if (tickets) {
    const ticketCounts = tickets.reduce((counts: { [key: string]: number }, ticket) => {
      const { ticket_typ } = ticket;
      counts[ticket_typ] = (counts[ticket_typ] || 0) + 1;
      return counts;
    }, {});

    info = Object.entries(ticketCounts)
      .map(([ticketTyp, count]) => `${count}x ${ticketTyp}`)
      .join(", ");
  }

  return stripPolishCharacters(info, polishCharacters);
};

const handleNamedCarnetBase = (carnetBase: ICarnetBaseObject, polishCharacters: boolean) => {
  if (!(carnetBase.first_name && carnetBase.last_name)) return undefined;

  return stripPolishCharacters((`${carnetBase.first_name} ${carnetBase.last_name}`).substring(0,maxLineStringlenght), polishCharacters);
};

const handleRowInformation = (order: IOrderObject, polishCharacters: boolean, ticket?: ITicketObject, selectedTickets?: ITicketObject[]) => {
  const tickets = selectedTickets || order.tickets;
  if (!order.hall_name || !tickets) return undefined;

  let rowArr = [];
  if (ticket){
    rowArr.push(stripPolishCharacters(ticket.spot_desc || "", polishCharacters))
  }else{
    tickets.map((singleTicket) => rowArr.push(stripPolishCharacters(singleTicket.spot_desc || "", polishCharacters)))
  }
  return rowArr
  // return stripPolishCharacters((ticket ? ticket.spot_desc : tickets[0].spot_desc) || "", polishCharacters);
};
// NON FISCAL PRINT
