import { usePrintingProblems } from "./";

import { IprintingProblems } from "./usePrintingProblems";

const useProductOrderPrintingProblems = () => {
  const { getActiveCashierPrintingProblems, selectNextProblem, saveActiveCashierPrintingProblems, setPrintingProblemsActiveTab, setPrintingProblemsOpen } = usePrintingProblems();

  const checkIfProductOrderIsSkipped = (id: number) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    return deepCopy.skippedProductOrders.includes(id);
  };

  const countSkippedProductOrders = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    return deepCopy.skippedProductOrders.length;
  };

  const getSkippedProductOrders = () => {
    return getActiveCashierPrintingProblems().skippedProductOrders || [];
  };

  const handleIgnoreProductOrder = (id: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredProductOrders.push(id);
    selectNextProblem(deepCopy.skippedProductOrders, id, setActiveId);
    deepCopy.skippedProductOrders = deepCopy.skippedProductOrders.filter((productOrderId) => productOrderId !== id);
    selectNextTabFromProductOrders(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleIgnoreProductOrders = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.skippedProductOrders.forEach((id) => {
      if (!deepCopy.ignoredProductOrders.includes(id)) deepCopy.ignoredProductOrders.push(id);
    });
    deepCopy.skippedProductOrders = [];
    selectNextTabFromProductOrders(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const selectNextTabFromProductOrders = (deepCopy: IprintingProblems) => {
    const skippedOrders = deepCopy.skippedOrders;
    const skippedCarnetBases = deepCopy.skippedCarnetBases;
    const skippedCarnetOrders = deepCopy.skippedCarnetOrders;
    const skippedProductOrders = deepCopy.skippedProductOrders;

    if (skippedProductOrders.length !== 0) return;

    if (skippedOrders.length !== 0) setPrintingProblemsActiveTab("orders");
    else if (skippedCarnetBases.length !== 0) setPrintingProblemsActiveTab("carnets");
    else if (skippedCarnetOrders.length !== 0) setPrintingProblemsActiveTab("carnetOrders");
  };

  const removeProductOrderProblem = (id: number, setActiveId: React.Dispatch<React.SetStateAction<number>>) => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    selectNextProblem(deepCopy.skippedProductOrders, id, setActiveId);
    deepCopy.skippedProductOrders = deepCopy.skippedProductOrders.filter((productOrderId) => productOrderId !== id);
    deepCopy.ignoredProductOrders = deepCopy.ignoredProductOrders.filter((productOrderId) => productOrderId !== id);
    selectNextTabFromProductOrders(deepCopy);
    saveActiveCashierPrintingProblems(deepCopy);
  };

  const handleRestoreAllProductOrders = () => {
    const deepCopy: IprintingProblems = JSON.parse(JSON.stringify(getActiveCashierPrintingProblems()));
    deepCopy.ignoredProductOrders.forEach((id) => {
      if (!deepCopy.skippedProductOrders.includes(id)) deepCopy.skippedProductOrders.push(id);
    });
    deepCopy.ignoredProductOrders = [];
    saveActiveCashierPrintingProblems(deepCopy);
    setPrintingProblemsOpen(false);
  };

  return {
    checkIfProductOrderIsSkipped,
    countSkippedProductOrders,
    getSkippedProductOrders,
    handleIgnoreProductOrder,
    handleIgnoreProductOrders,
    removeProductOrderProblem,
    handleRestoreAllProductOrders
  };
};
export default useProductOrderPrintingProblems;
