import { baseInstance } from "../../axiosCashier";
import { IfetchEvent, IfetchEvents, IfetchEventsCities, IrecipientsCountEvent } from "../../interfaces/eventInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/events";

export const fetchEvent = (params: IfetchEvent) => {
  const queryParams = fetchQueryParams(params, ["id"]);
  return baseInstance.get(`${BASE_URL}/${params.id}.json${queryParams}`);
};

export const fetchEvents = (params: IfetchEvents) => {
  const queryParams = fetchQueryParams(params, ["event_template_id"]);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};

export const fetchEventsCities = (params: IfetchEventsCities) => {
  const queryParams = fetchQueryParams(params, ["cooperator_id"]);
  return baseInstance.get(`${BASE_URL}/cities.json${queryParams}`);
};

export const recipientsCountEvent = (params: IrecipientsCountEvent) => {
  const queryParams = fetchQueryParams(params, ["id", "cooperator_id"]);
  return baseInstance.get(`${BASE_URL}/${params.id}/recipients_count.json${queryParams}`);
};
