import { baseInstance } from "../../axiosCashier";
import {
  IconfirmCarnetOrder,
  IcreateCarnetOrder,
  IdestroyCarnetOrder,
  IfetchCarnetOrder,
  IfetchCarnetOrderForPosNewInvoice,
  IfetchCarnetOrders,
  IfetchCarnetOrdersForPosNewInvoice,
  IprintCarnetOrder,
  IresendEmailCarnetOrder,
  IreservationConfirmCarnetOrder,
  IupdateFormCarnetOrder
} from "../../interfaces/carnetOrderInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/carnet_orders";

export const confirmCarnetOrder = (params: IconfirmCarnetOrder) => {
  const queryParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/confirm.json`, queryParams);
};

export const createCarnetOrder = (params: IcreateCarnetOrder) => {
  return baseInstance.post(`${BASE_URL}.json`, params);
};

export const destroyCarnetOrder = (params: IdestroyCarnetOrder) => {
  return baseInstance.delete(`${BASE_URL}/${params.id}.json`);
};

export const fetchCarnetOrder = (params: IfetchCarnetOrder) => {
  const queryParams = fetchQueryParams(params, ["id"]);
  return baseInstance.get(`${BASE_URL}/${params.id}.json${queryParams}`);
};

export const fetchCarnetOrderForPosNewInvoice = (params: IfetchCarnetOrderForPosNewInvoice) => {
  return baseInstance.get(`${BASE_URL}/${params.id}/show_for_pos_new_invoice.json`);
};

export const fetchCarnetOrders = (params: IfetchCarnetOrders) => {
  const queryParams = fetchQueryParams(params, ["cooperator_id", "event_id"]);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};

export const fetchCarnetOrdersForPosNewInvoice = (params: IfetchCarnetOrdersForPosNewInvoice) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}/index_for_pos_new_invoice.json${queryParams}`);
};

export const printCarnetOrder = (params: IprintCarnetOrder) => {
  return baseInstance.patch(`${BASE_URL}/${params.id}/print.json`);
};

export const resendEmailCarnetOrder = (params: IresendEmailCarnetOrder) => {
  return baseInstance.patch(`${BASE_URL}/${params.id}/resend_email.json`);
};

export const reservationConfirmCarnetOrder = (params: IreservationConfirmCarnetOrder) => {
  const queryParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/reservation_confirm.json`, queryParams);
};

export const updateFormCarnetOrder = (params: IupdateFormCarnetOrder) => {
  const updateFormParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/update_form.json`, updateFormParams);
};
