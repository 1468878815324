import { Fragment } from "react";

import { NoObjectsPresent, Pagination } from "./";

import { IObjectsWithPagination } from "../../interfaces/sharedComponentInterfaces";

const ObjectsWithPagination = (props: IObjectsWithPagination) => {
  const { children, totalCount, page, setPage, perPage, setPerPage, settings, settingType, settingKey, size } = props;

  return totalCount === 0 ? (
    <NoObjectsPresent />
  ) : (
    <Fragment>
      {children}
      <Pagination totalCount={totalCount} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} settings={settings} settingType={settingType} settingKey={settingKey} size={size} />
    </Fragment>
  );
};

export default ObjectsWithPagination;
