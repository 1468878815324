import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AiOutlineAppstore, AiOutlineUnorderedList } from "react-icons/ai";

import { Button, WithShadow } from "./";

import { useCashierSettings } from "../../hooks";

import { ISearchBar } from "../../interfaces/sharedComponentInterfaces";

const SearchBar = (props: ISearchBar) => {
  const { additionalButton, children, handleSearchClick, isTileView, setIsTileView, unpaid, setUnpaid, settings, settingType, hideTile } = props;
  const { t } = useTranslation();
  const { changeCurrentCashierSetting } = useCashierSettings();

  const handleChangeView = () => {
    if (setIsTileView) {
      setIsTileView((oldIsTileView) => {
        return !oldIsTileView;
      });
    }
  };

  useEffect(() => {
    if (settings && settingType) {
      changeCurrentCashierSetting(settingType, { ...settings, view: !isTileView ? "rows" : "tiles" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTileView]);

  return (
    <WithShadow testId="search-bar" customClasses="min-h-[42px]">
      <Fragment>
        <div className="flex justify-between flex-1">
          <div className="flex gap-2 items-center">{children}</div>
          <div className="flex gap-2">
            {setUnpaid && (
              <div className="flex items-center">
                <Button buttonStyle="primary" customClasses={`h-full transition-all ${!unpaid ? "bg-sky-600 hover:bg-sky-500" : "bg-zinc-300 hover:bg-zinc-400"}`} onClick={() => setUnpaid(false)}>
                  <p>{t("search_bar.paid")}</p>
                </Button>
                <Button buttonStyle="primary" customClasses={`h-full transition-all ${unpaid ? "bg-sky-600 hover:bg-sky-500" : "bg-zinc-300 hover:bg-zinc-400"}`} onClick={() => setUnpaid(true)}>
                  <p>{t("search_bar.unpaid")}</p>
                </Button>
              </div>
            )}
            <Button buttonStyle="success" onClick={children ? handleSearchClick : undefined} testId="search-bar-submit">
              <p>{t("search_bar.search")}</p>
            </Button>
          </div>
        </div>
        {!hideTile && (
          <Button buttonStyle="primary" onClick={handleChangeView} customClasses="text-3xl py-0">
            {!isTileView ? <AiOutlineUnorderedList /> : <AiOutlineAppstore />}
          </Button>
        )}
        {additionalButton}
      </Fragment>
    </WithShadow>
  );
};

export default SearchBar;
