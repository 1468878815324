import { useLayoutEffect, useState } from "react";

import { useBaseStore } from "../store";

import { fetchCarnetOrder } from "../services/objects/carnetOrders.service";
import { handleFetchObjectResponse } from "../services/responseDataHandler";
import { ICarnetOrderObject } from "../interfaces/carnetOrderInterfaces";

const useCarnetOrder = (id?: number) => {
  const refresh = useBaseStore((state) => state.refresh);
  const setGlobalLoader = useBaseStore((state) => state.setGlobalLoader);
  const [carnetOrder, setCarnetOrder] = useState<ICarnetOrderObject | undefined>(undefined);

  const fetchObject = () => {
    if (!id || id === -1) return;

    fetchCarnetOrder({ id: id }).then((response) => {
      handleFetchObjectResponse({ response: response, setObject: setCarnetOrder, setGlobalLoader: setGlobalLoader });
    });
  };

  useLayoutEffect(() => {
    fetchObject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refresh]);

  return { carnetOrder, setCarnetOrder };
};
export default useCarnetOrder;
