import { baseInstance } from "../../axiosCashier";
import { IfetchProducts, IupdateProduct } from "../../interfaces/productInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/pos_products";

export const fetchProducts = (params: IfetchProducts) => {
  const queryParams = fetchQueryParams(params, ["cooperator_id"]);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};

export const updateProduct = (params: IupdateProduct) => {
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}.json`, { pos_product: filteredParams });
};
